import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { View, StyleSheet, Text } from '@react-pdf/renderer';
var styles = StyleSheet.create({
    preventivoSection: {
        marginTop: 1,
        marginBottom: 1,
    },
    detallePreventivo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#0FBAC6', // Color de encabezado corregido
        color: 'white',
        padding: 5,
        fontSize: 10,
    },
    detalleItem: {
        flex: 1,
        textAlign: 'left',
        paddingLeft: 5,
    },
    sectionHeader: {
        flexDirection: 'row',
        backgroundColor: '#d9d9d9', // Color de encabezado para Mantenimiento
        color: 'black',
        padding: 5,
        fontSize: 10,
    },
    title: {
        width: '60%', // El título ocupa más espacio
        textAlign: 'left',
        paddingRight: 10,
    },
    pasoHeader: {
        width: '13.33%',
        textAlign: 'center',
    },
    falloHeader: {
        width: '13.33%',
        textAlign: 'center',
    },
    naHeader: {
        width: '13.33%',
        textAlign: 'center',
    },
    result: {
        width: '13.33%', // Ajuste para alinear las columnas de resultados
        textAlign: 'center',
    },
    resultPaso: {
        width: '13.33%', // Ajuste para alinear las columnas de resultados
        textAlign: 'center',
        color: 'green',
    },
    resultFallo: {
        width: '13.33%', // Ajuste para alinear las columnas de resultados
        textAlign: 'center',
        color: 'red'
    },
    cuantitativoSectionHeader: {
        flexDirection: 'row',
        backgroundColor: '#d9d9d9', // Color de encabezado para Cuantitativo
        color: 'black',
        padding: 5,
        fontSize: 10,
    },
    cuantitativoTitle: {
        width: '60%', // Usamos el mismo ancho para todas las columnas
        textAlign: 'left',
    },
    cuantitativoResultTitle: {
        width: '14.28%', // Usamos el mismo ancho para todas las columnas
        textAlign: 'center',
    },
    cuantitativoResultTitlePaso: {
        width: '14.28%', // Usamos el mismo ancho para todas las columnas
        textAlign: 'center',
        color: 'green'
    },
    cuantitativoResultTitleFallo: {
        width: '14.28%', // Usamos el mismo ancho para todas las columnas
        textAlign: 'center',
        color: 'red'
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
        padding: 5,
        fontSize: 10,
    },
});
var OrdenesPDFPreventivoSection = function (_a) {
    var _b, _c, _d, _e;
    var orden = _a.orden;
    // Extrayendo los valores necesarios para los detalles del Preventivo
    var actividadPreventivo = (_b = orden.ids_visitas) === null || _b === void 0 ? void 0 : _b.flatMap(function (visita) {
        return visita.actividades.filter(function (actividad) { return actividad.id_protocolo.title === 'Preventivo'; });
    })[0];
    var ejecutadoDate = (actividadPreventivo === null || actividadPreventivo === void 0 ? void 0 : actividadPreventivo.date_created) || 'N/A';
    var codigo = orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.codigo || 'N/A';
    var version = "".concat(orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.version || 'N/A', " (").concat(orden.id_solicitud_servicio.id_equipo.modelo_equipos.id_preventivo.fecha || 'N/A', ")");
    return (_jsxs(View, { style: styles.preventivoSection, children: [_jsxs(View, { style: styles.detallePreventivo, children: [_jsx(Text, { style: styles.detalleItem, children: "Preventivo" }), _jsxs(Text, { style: styles.detalleItem, children: ["Ejecutado: ", ejecutadoDate] }), _jsxs(Text, { style: styles.detalleItem, children: ["C\u00F3digo: ", codigo] }), _jsxs(Text, { style: styles.detalleItem, children: ["Versi\u00F3n: ", version] })] }), _jsxs(View, { style: styles.sectionHeader, children: [_jsx(Text, { style: styles.title, children: "Pruebas Cualitativas" }), _jsx(Text, { style: styles.pasoHeader, children: "Pas\u00F3" }), _jsx(Text, { style: styles.falloHeader, children: "Fall\u00F3" }), _jsx(Text, { style: styles.naHeader, children: "No Aplica" })] }), (_c = orden.ids_visitas) === null || _c === void 0 ? void 0 : _c.map(function (visita) {
                return visita.actividades
                    .filter(function (actividad) { return actividad.id_protocolo.title === 'Preventivo'; })
                    .flatMap(function (actividad) {
                    return actividad.ids_campos_preventivo
                        .filter(function (campo) { return campo.tipo === 'Cualitativo'; })
                        .map(function (campo, index) { return (_jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.title, children: campo.title }), _jsx(Text, { style: styles.resultPaso, children: campo.resultado === 'paso' ? 'X' : '' }), _jsx(Text, { style: styles.resultFallo, children: campo.resultado === 'fallo' ? 'X' : '' }), _jsx(Text, { style: styles.result, children: campo.resultado === 'n/a' ? 'X' : '' })] }, index)); });
                });
            }), _jsxs(View, { style: styles.sectionHeader, children: [_jsx(Text, { style: styles.title, children: "Mantenimiento" }), _jsx(Text, { style: styles.pasoHeader, children: "Pas\u00F3" }), _jsx(Text, { style: styles.falloHeader, children: "Fall\u00F3" }), _jsx(Text, { style: styles.naHeader, children: "No Aplica" })] }), (_d = orden.ids_visitas) === null || _d === void 0 ? void 0 : _d.map(function (visita) {
                return visita.actividades
                    .filter(function (actividad) { return actividad.id_protocolo.title === 'Preventivo'; })
                    .flatMap(function (actividad) {
                    return actividad.ids_campos_preventivo
                        .filter(function (campo) { return campo.tipo === 'Mantenimiento'; })
                        .map(function (campo, index) { return (_jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.title, children: campo.title }), _jsx(Text, { style: styles.resultPaso, children: campo.resultado === 'paso' ? 'X' : '' }), _jsx(Text, { style: styles.resultFallo, children: campo.resultado === 'fallo' ? 'X' : '' }), _jsx(Text, { style: styles.result, children: campo.resultado === 'n/a' ? 'X' : '' })] }, index)); });
                });
            }), _jsxs(View, { style: styles.cuantitativoSectionHeader, children: [_jsx(Text, { style: styles.cuantitativoTitle, children: "Pruebas Cuantitativas" }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: "Min" }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: "Max" }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: "Med" }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: "Uni" }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: "Pas\u00F3" }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: "Fall\u00F3" })] }), (_e = orden.ids_visitas) === null || _e === void 0 ? void 0 : _e.map(function (visita) {
                return visita.actividades
                    .filter(function (actividad) { return actividad.id_protocolo.title === 'Preventivo'; })
                    .flatMap(function (actividad) {
                    return actividad.ids_campos_preventivo
                        .filter(function (campo) { return campo.tipo === 'Cuantitativo'; })
                        .map(function (campo, index) { return (_jsxs(View, { style: styles.row, children: [_jsx(Text, { style: styles.cuantitativoTitle, children: campo.title }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: campo.minimo }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: campo.maximo }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: campo.medida }), _jsx(Text, { style: styles.cuantitativoResultTitle, children: campo.unidad }), _jsx(Text, { style: styles.cuantitativoResultTitlePaso, children: campo.resultado === 'paso' ? 'X' : '' }), _jsx(Text, { style: styles.cuantitativoResultTitleFallo, children: campo.resultado === 'fallo' ? 'X' : '' })] }, index)); });
                });
            })] }));
};
export default OrdenesPDFPreventivoSection;
