// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrdenesPDFComponent-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .OrdenesPDFComponent-modal-content {
    padding: 20px;
    background-color: #ffffff;
    height: 14vh;
    width: 6vw;
    border-radius: 5px;
    position: relative;
  }
  
  
  .OrdenesPDFComponent-modal-close-icon {
    color: #4F7A76;
    height: 3vh !important;
    width: 1.5vw !important;
    cursor: pointer;
    position: absolute;
    top: 0.3vh;
    left: 0.2vw;
  }
  
  .OrdenesPDFComponent-modal-pdf-icon {
    color: #fa0000;
    height: 10vh !important;
    width: 4vw !important;
    cursor: pointer;
    position: absolute;
    margin: 6%;
    top: 2vh;
    left: 0.7vw;
  }
  
    .OrdenesPDFComponent-modal-word-icon {
      color: #0043fa;
      height: 10vh !important;
      width: 4vw !important;
      cursor: pointer;
      position: absolute;
      margin: 6%;
      top: 2vh;
      left: 1.7vw;
    }
    
  
  `, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/ordenesPDF/styles/OrdenesPDFComponent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,kBAAkB;EACpB;;;EAGA;IACE,cAAc;IACd,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,WAAW;EACb;;EAEA;IACE,cAAc;IACd,uBAAuB;IACvB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,WAAW;EACb;;IAEE;MACE,cAAc;MACd,uBAAuB;MACvB,qBAAqB;MACrB,eAAe;MACf,kBAAkB;MAClB,UAAU;MACV,QAAQ;MACR,WAAW;IACb","sourcesContent":[".OrdenesPDFComponent-modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .OrdenesPDFComponent-modal-content {\n    padding: 20px;\n    background-color: #ffffff;\n    height: 14vh;\n    width: 6vw;\n    border-radius: 5px;\n    position: relative;\n  }\n  \n  \n  .OrdenesPDFComponent-modal-close-icon {\n    color: #4F7A76;\n    height: 3vh !important;\n    width: 1.5vw !important;\n    cursor: pointer;\n    position: absolute;\n    top: 0.3vh;\n    left: 0.2vw;\n  }\n  \n  .OrdenesPDFComponent-modal-pdf-icon {\n    color: #fa0000;\n    height: 10vh !important;\n    width: 4vw !important;\n    cursor: pointer;\n    position: absolute;\n    margin: 6%;\n    top: 2vh;\n    left: 0.7vw;\n  }\n  \n    .OrdenesPDFComponent-modal-word-icon {\n      color: #0043fa;\n      height: 10vh !important;\n      width: 4vw !important;\n      cursor: pointer;\n      position: absolute;\n      margin: 6%;\n      top: 2vh;\n      left: 1.7vw;\n    }\n    \n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
