import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/CamposCard.css';
var CamposCard = function (_a) {
    var campo = _a.campo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "CamposCard-box ".concat(isSelected ? 'CamposCard-selected' : '', "\n}");
    return (_jsxs("div", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '93%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 1000, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#4F7A76',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#4F7A76',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx(FormatListBulletedOutlinedIcon, { className: "CamposCard-icon" }), _jsxs("div", { className: "CamposCard-overlap", children: [_jsx("div", { className: "CamposCard-title", onClick: onClick, children: campo.title }), _jsxs("div", { className: "CamposCard-id", children: ["ID: ", campo._id] })] }), _jsxs("div", { className: "CamposCard-tipo", children: ["TIPO: ", campo.id_tipo.nombre] })] }, campo._id));
};
export default CamposCard;
