import axios from '../../../utils/config/axios.config';
// Función de manejo de errores común
var handleError = function (error) {
    if (error.response) {
        var _a = error.response, status_1 = _a.status, data = _a.data;
        if (status_1 === 500) {
            console.error('Error 500:', data);
        }
        // Manejo de otros códigos de estado HTTP según sea necesario
    }
    else {
        console.error('Error desconocido:', error);
    }
    throw error;
};
// Función para obtener todos los equipos con parámetros opcionales de límite y página
export var getAllEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/equipos', options)
        .then(function (response) { return response.data; }) // Devolver los datos directamente como en getAllUsers
        .catch(handleError); // Utiliza el mismo manejo de errores que en getAllUsers
};
// Resto del código...
export var getEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/equipos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_2 = error.response.status;
            if (status_2 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
export var createEquipo = function (token, equipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos', equipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_3 = error.response.status;
            if (status_3 === 500) {
                // window.location.href = '/login';
                console.log(error);
            }
        }
        throw error;
    });
};
// Nueva función para crear equipos en masivo
export var createMultipleEquipos = function (token, equiposData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/equipos/crear-masivo', equiposData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateEquipo = function (token, id, equipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/equipos?id=".concat(id), equipoData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_4 = error.response.status;
            if (status_4 === 500) {
                // window.location.href = '/login';
                console.log(error);
            }
        }
        throw error;
    });
};
export var deleteEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/equipos/", options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        if (error.response) {
            var status_5 = error.response.status;
            if (status_5 === 500) {
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
