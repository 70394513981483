import axios from '../../../utils/config/axios.config';
// Get all costos_equipos
export var getAllCostosEquipos = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/costos-equipos', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Get a costo_equipo by ID
export var getCostoEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/costos-equipos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Create a new costo_equipo
export var createCostoEquipo = function (token, costoEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/costos-equipos', costoEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Update a costo_equipo by ID
export var updateCostoEquipo = function (token, id, costoEquipoData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/costos-equipos?id=".concat(id), costoEquipoData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Delete a costo_equipo by ID
export var deleteCostoEquipoById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/costos-equipos/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Handle errors in axios responses
var handleError = function (error) {
    if (error.response) {
        var status_1 = error.response.status;
        if (status_1 === 500) {
            // Token inválido o expirado
            console.error(error);
        }
    }
};
