var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import RegisterCotizacionForm from '../components/cotizaciones/forms/RegisterCotizacionForm';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { getAllClients } from '../../users/services/clientsService';
import './styles/RegisterCotizacionPage.css';
var RegisterCotizacionPage = function () {
    var _a = useState(false), isFormVisible = _a[0], setIsFormVisible = _a[1];
    var _b = useState(undefined), idCliente = _b[0], setIdCliente = _b[1];
    var _c = useState(undefined), nombreCliente = _c[0], setNombreCliente = _c[1];
    var _d = useState(''), search = _d[0], setSearch = _d[1];
    var _e = useState([]), filteredClients = _e[0], setFilteredClients = _e[1];
    var _f = useState([]), clients = _f[0], setClients = _f[1];
    var _g = useState(''), debouncedSearch = _g[0], setDebouncedSearch = _g[1]; // Estado para manejar el debounce
    var _h = useState(false), isLoading = _h[0], setIsLoading = _h[1]; // Estado para manejar el loading
    var _j = useState(null), selectedClientId = _j[0], setSelectedClientId = _j[1]; // Estado para manejar cliente seleccionado
    var token = useSessionStorage('sessionJWTToken');
    var navigate = useNavigate();
    // Cargar los clientes al montar el componente
    useEffect(function () {
        var fetchClients = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!token)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getAllClients(token)];
                    case 2:
                        response = _a.sent();
                        setClients(response.clients || []);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error al cargar los clientes:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchClients();
    }, [token]);
    // Implementar el debounce para el input
    useEffect(function () {
        setIsLoading(true); // Mostrar el loading al empezar a escribir
        var handler = setTimeout(function () {
            setDebouncedSearch(search);
            setIsLoading(false); // Ocultar el loading después del debounce
        }, 500); // 500ms de retraso antes de aplicar el filtro
        return function () {
            clearTimeout(handler); // Limpiar el timeout si el usuario sigue escribiendo
        };
    }, [search]);
    // Filtrar los clientes cuando debouncedSearch cambie
    useEffect(function () {
        if (debouncedSearch.trim() === '') {
            setFilteredClients([]); // No mostrar resultados si el input está vacío
            return;
        }
        var filtered = clients.filter(function (client) {
            return client.client_name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
                client._id.toLowerCase().includes(debouncedSearch.toLowerCase());
        });
        setFilteredClients(filtered);
    }, [debouncedSearch, clients]);
    var handleSelectClient = function (client) {
        setIdCliente(client._id);
        setNombreCliente(client.client_name);
        setSelectedClientId(client._id); // Marcar el cliente seleccionado
        // No limpiar el campo de búsqueda para que el cliente seleccionado quede visible
    };
    var handleCancelSelection = function () {
        navigate('/cotizaciones');
    };
    return (_jsxs("div", { className: "RegisterCotizacionPage-container", children: [_jsx(DashboardMenuLateral, {}), !isFormVisible ? (_jsxs("div", { className: "RegisterCotizacionPage-client-selection", children: [_jsx("h2", { children: "Seleccione el Cliente para la cotizaci\u00F3n: " }), _jsx("input", { type: "text", placeholder: "Buscar...", value: search, onChange: function (e) { return setSearch(e.target.value); }, className: "RegisterCotizacionPage-client-search-input" }), isLoading && _jsx("p", { className: "RegisterCotizacionPage-loading", children: "Cargando..." }), !isLoading && debouncedSearch.trim() !== '' && filteredClients.length > 0 && (_jsx("ul", { className: "RegisterCotizacionPage-client-list", children: filteredClients.map(function (client) { return (_jsx("li", { onClick: function () { return handleSelectClient(client); }, className: "RegisterCotizacionPage-client-item ".concat(selectedClientId === client._id ? 'selected' : ''), children: "".concat(client.client_name, " - ").concat(client._id) }, client._id)); }) })), idCliente && (_jsxs("div", { className: "RegisterCotizacionPage-client-actions", children: [_jsxs("p", { children: [_jsx("strong", { children: "Cliente seleccionado:" }), " ", nombreCliente] }), _jsx("button", { onClick: function () { return setIsFormVisible(true); }, className: "RegisterCotizacionPage-continue-button", children: "Continuar" }), _jsx("button", { onClick: handleCancelSelection, className: "RegisterCotizacionPage-cancel-button", children: "Cancelar" })] }))] })) : (_jsx(RegisterCotizacionForm, { idCliente: idCliente, nombreCliente: nombreCliente, onCotizacionSuccess: function () {
                    alert('Cotización registrada exitosamente.');
                    navigate('/cotizaciones');
                }, onCancel: handleCancelSelection }))] }));
};
export default RegisterCotizacionPage;
