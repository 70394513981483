var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { createCotizacion, getPresignedUrlForFirma } from '../../../cotizaciones/services/cotizacionesService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getAllRepuestosEquipos } from '../../../equipos/services/repuestosEquiposService';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './styles/RegisterCotizacionOrden.css';
import { generateGetPresignedUrlForUserFirma, getUserById } from '../../../users/services/usersService';
var RegisterCotizacionOrden = function (_a) {
    var idOrden = _a.idOrden, onCotizacionSuccess = _a.onCotizacionSuccess, onCancel = _a.onCancel, idCliente = _a.idCliente, nombreCliente = _a.nombreCliente;
    var _b = useState([]), repuestos = _b[0], setRepuestos = _b[1];
    var _c = useState([]), itemsAdicionales = _c[0], setItemsAdicionales = _c[1];
    var _d = useState(''), mensaje = _d[0], setMensaje = _d[1];
    var _e = useState(''), condicion = _e[0], setCondicion = _e[1];
    var _f = useState(''), condiciones = _f[0], setCondiciones = _f[1];
    var _g = useState(''), firma = _g[0], setFirma = _g[1];
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var _j = useState(''), error = _j[0], setError = _j[1];
    var token = useSessionStorage('sessionJWTToken');
    var userId = useSessionStorage('userId');
    var userName = useSessionStorage('userName');
    var estadoPendienteId = "6613312f445b229362edc87e";
    var _k = useState([]), repuestosEquipo = _k[0], setRepuestosEquipo = _k[1];
    var _l = useState([]), repuestosSeleccionados = _l[0], setRepuestosSeleccionados = _l[1];
    var _m = useState(false), firmaGuardada = _m[0], setFirmaGuardada = _m[1];
    var now = new Date();
    var formattedDateCreated = "".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0'));
    useEffect(function () {
        var fetchRepuestos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, repuestosDisponibles, repuestosFiltrados, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!token || !idCliente)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getAllRepuestosEquipos(token)];
                    case 2:
                        result = _a.sent();
                        repuestosDisponibles = (result === null || result === void 0 ? void 0 : result.repuestoEquipos) || [];
                        repuestosFiltrados = repuestosDisponibles.filter(function (repuesto) { return repuesto.id_cliente._id === idCliente; });
                        setRepuestosEquipo(repuestosFiltrados);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error al cargar repuestos:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchRepuestos();
    }, [token, idCliente]);
    useEffect(function () {
        if (!token || !userId)
            return;
        // Obtener la información del usuario
        getUserById(token, userId)
            .then(function (response) {
            var userFirmaImage = response.data.firma_image; // Obtener la firma del usuario
            if (userFirmaImage) {
                // Si existe `firma_image`, generar la URL firmada para obtener la imagen
                generateGetPresignedUrlForUserFirma(token, userFirmaImage)
                    .then(function (url) {
                    setFirma(url); // Establecer la URL de la firma
                    setFirmaGuardada(true); // Indicar que la firma está guardada
                })
                    .catch(function (err) { return console.error('Error fetching user firma URL:', err); });
            }
            else {
                setFirma(''); // No hay firma disponible
                setFirmaGuardada(false);
            }
        })
            .catch(function (err) { return console.error('Error fetching user data:', err); });
    }, [token, userId]);
    var handleRepuestoChange = function (id_repuesto) {
        var repuestoSeleccionado = repuestosEquipo.find(function (repuesto) { return repuesto._id === id_repuesto; });
        if (!repuestoSeleccionado)
            return;
        setRepuestosSeleccionados(function (prevRepuestos) { return __spreadArray(__spreadArray([], prevRepuestos, true), [
            { id_repuesto: repuestoSeleccionado._id, cantidad: 1, valor_unitario: repuestoSeleccionado.repuesto_precio }
        ], false); });
    };
    var actualizarRepuestoSeleccionado = function (index, campo, valor) {
        var nuevosRepuestos = repuestosSeleccionados.map(function (repuesto, i) {
            var _a;
            if (i === index) {
                return __assign(__assign({}, repuesto), (_a = {}, _a[campo] = valor, _a));
            }
            return repuesto;
        });
        setRepuestosSeleccionados(nuevosRepuestos);
    };
    var removerRepuestoSeleccionado = function (indexParaRemover) {
        setRepuestosSeleccionados(repuestosSeleccionados.filter(function (_, index) { return index !== indexParaRemover; }));
    };
    // Añadir un nuevo item adicional
    var agregarItemAdicional = function () {
        var nuevoItem = { descripcion: '', cantidad: 1, valor_unitario: 0 };
        setItemsAdicionales(__spreadArray(__spreadArray([], itemsAdicionales, true), [nuevoItem], false));
    };
    // Actualizar un item adicional existente
    var actualizarItemAdicional = function (index, campo, valor) {
        var nuevosItems = itemsAdicionales.map(function (item, i) {
            var _a;
            if (i === index) {
                return __assign(__assign({}, item), (_a = {}, _a[campo] = valor, _a));
            }
            return item;
        });
        setItemsAdicionales(nuevosItems);
    };
    // Eliminar un item adicional
    var eliminarItemAdicional = function (indexParaEliminar) {
        setItemsAdicionales(itemsAdicionales.filter(function (_, index) { return index !== indexParaEliminar; }));
    };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var blob, fileName, presignedUrlObject, presignedUrl, observacionEstado, uploadedImageUrl, cotizacionData, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    setLoading(true);
                    setError('');
                    if (!token) {
                        setError('No estás autenticado');
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 7, , 8]);
                    if (!firma) return [3 /*break*/, 6];
                    return [4 /*yield*/, fetch(firma).then(function (res) { return res.blob(); })];
                case 2:
                    blob = _a.sent();
                    fileName = "firma-".concat(userId, "-").concat(Date.now(), ".png");
                    return [4 /*yield*/, getPresignedUrlForFirma(token, fileName)];
                case 3:
                    presignedUrlObject = _a.sent();
                    presignedUrl = presignedUrlObject.url;
                    console.log('Presigned URL: ', presignedUrl);
                    // Subir la imagen de la firma a S3 usando la URL firmada
                    return [4 /*yield*/, fetch(presignedUrl, {
                            method: 'PUT',
                            headers: { 'Content-Type': 'image/png' },
                            body: blob,
                        })];
                case 4:
                    // Subir la imagen de la firma a S3 usando la URL firmada
                    _a.sent();
                    observacionEstado = "Estado pendiente generado, usuario: ".concat(userId);
                    uploadedImageUrl = presignedUrl.split('?')[0];
                    cotizacionData = {
                        id_cliente: idCliente,
                        id_orden: idOrden, // Usar idOrden proporcionado como prop
                        id_creador: userId,
                        id_estado: estadoPendienteId,
                        ids_repuestos: repuestosSeleccionados,
                        items_adicionales: itemsAdicionales,
                        fecha_creation: formattedDateCreated,
                        cambio_estado: formattedDateCreated,
                        mensaje: mensaje,
                        observacion_estado: observacionEstado,
                        condiciones: condiciones,
                        firma: firma,
                        firma_username: userName,
                    };
                    // Crear la cotización con la firma incluida
                    return [4 /*yield*/, createCotizacion(token, cotizacionData)];
                case 5:
                    // Crear la cotización con la firma incluida
                    _a.sent();
                    _a.label = 6;
                case 6:
                    setLoading(false);
                    onCotizacionSuccess(); // Notificar al componente padre sobre el éxito
                    return [3 /*break*/, 8];
                case 7:
                    error_2 = _a.sent();
                    console.error('Error al registrar la cotización:', error_2);
                    setError('Ocurrió un error al registrar la cotización');
                    setLoading(false);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("form", { onSubmit: handleSubmit, className: "RegisterCotizacionOrden-box", children: _jsxs("div", { className: 'RegisterCotizacionOrden-register-container', children: [_jsx("div", { className: "RegisterCotizacionOrden-overlap", children: _jsx("div", { className: "RegisterCotizacionOrden-title-t", children: "AGREGAR COTIZACI\u00D3N" }) }), _jsxs("div", { className: "RegisterCotizacionOrden-register-cotizacion", children: [_jsx("div", { className: 'RegisterCotizacionOrden-client-t', children: "Cliente" }), _jsx("div", { className: 'RegisterCotizacionOrden-client-name', children: nombreCliente }), _jsx("div", { className: 'RegisterCotizacionOrden-orden-id-t', children: "Orden Relacionada (opcional)" }), _jsx("div", { className: 'RegisterCotizacionOrden-orden-id', children: idOrden }), _jsx("div", { className: 'RegisterCotizacionOrden-mensaje-t', children: "Mensaje: *" }), _jsx("textarea", { className: "RegisterCotizacionOrden-mensaje-value", value: mensaje, onChange: function (e) { return setMensaje(e.target.value); }, placeholder: "Mensaje", required: true }), _jsx("div", { className: 'RegisterCotizacionOrden-condicion-t', children: "Condiciones: *" }), _jsx("textarea", { className: "RegisterCotizacionOrden-condicion-value", value: condiciones, onChange: function (e) { return setCondiciones(e.target.value); }, placeholder: "Condiciones", required: true }), _jsx("div", { className: "RegisterCotizacionOrden-repuestos-title", children: "Repuestos" }), _jsxs("select", { onChange: function (e) { return handleRepuestoChange(e.target.value); }, className: "RegisterCotizacionOrden-repuestos-select", children: [_jsx("option", { value: "", children: "Seleccione un repuesto" }), repuestosEquipo.map(function (repuesto) { return (_jsx("option", { value: repuesto._id, children: repuesto.repuesto_name }, repuesto._id)); })] }), repuestosSeleccionados.map(function (repuesto, index) {
                            var repuestoEncontrado = repuestosEquipo.find(function (r) { return r._id === repuesto.id_repuesto; });
                            // Si el repuesto no se encuentra, no renderizamos este bloque
                            if (!repuestoEncontrado) {
                                return null;
                            }
                            return (_jsxs("div", { className: 'RegisterCotizacionOrden-repuestos-selected-div', children: [_jsx("div", { className: 'RegisterCotizacionOrden-repuestos-selected-title', children: "Repuesto" }), _jsx("p", { className: 'RegisterCotizacionOrden-repuestos-selected-value', children: repuestoEncontrado.repuesto_name }), _jsx("div", { className: 'RegisterCotizacionOrden-repuestos-cantidad-title', children: "Cantidad" }), _jsx("input", { className: 'RegisterCotizacionOrden-repuestos-cantidad-input', type: "number", value: repuesto.cantidad, onChange: function (e) { return actualizarRepuestoSeleccionado(index, 'cantidad', Number(e.target.value)); }, required: true }), _jsx("div", { className: 'RegisterCotizacionOrden-repuestos-valoru-title', children: "Valor Unitario" }), _jsx("input", { className: 'RegisterCotizacionOrden-repuestos-valoru-input', type: "number", value: repuesto.valor_unitario, onChange: function (e) { return actualizarRepuestoSeleccionado(index, 'valor_unitario', Number(e.target.value)); }, required: true }), _jsx(CancelIcon, { className: 'RegisterCotizacionOrden-repuestos-remove-icon', type: "button", onClick: function () { return removerRepuestoSeleccionado(index); } })] }, index));
                        }), _jsxs("div", { className: 'RegisterCotizacionOrden-items-div', children: [_jsx("div", { className: 'RegisterCotizacionOrden-items-title', children: "Items Adicionales" }), _jsx(AddCircleIcon, { className: 'RegisterCotizacionOrden-items-add-icon', onClick: agregarItemAdicional }), itemsAdicionales.map(function (item, index) { return (_jsxs("div", { className: 'RegisterCotizacionOrden-items-section', children: [_jsx("div", { className: 'RegisterCotizacionOrden-items-description-title', children: "Descripci\u00F3n" }), _jsx("textarea", { className: 'RegisterCotizacionOrden-items-description-input', value: item.descripcion, onChange: function (e) { return actualizarItemAdicional(index, 'descripcion', e.target.value); }, placeholder: "Descripci\u00F3n", required: true }), _jsx("div", { className: 'RegisterCotizacionOrden-items-cantidad-title', children: "Cantidad" }), _jsx("input", { className: 'RegisterCotizacionOrden-items-cantidad-input', type: "number", value: item.cantidad, onChange: function (e) { return actualizarItemAdicional(index, 'cantidad', Number(e.target.value)); }, placeholder: "Cantidad", required: true }), _jsx("div", { className: 'RegisterCotizacionOrden-items-valoru-title', children: "Valor Unitario" }), _jsx("input", { className: 'RegisterCotizacionOrden-items-valoru-input', type: "number", value: item.valor_unitario, onChange: function (e) { return actualizarItemAdicional(index, 'valor_unitario', Number(e.target.value)); }, placeholder: "Valor unitario", required: true }), _jsx(CancelIcon, { className: 'RegisterCotizacionOrden-items-remove-icon', onClick: function () { return eliminarItemAdicional(index); } })] }, index)); })] }), _jsxs("div", { className: "RegisterCotizacionOrden-firma", children: [_jsx("div", { className: "RegisterCotizacionOrden-firma-title", children: "Firma" }), _jsx("div", { className: "RegisterCotizacionOrden-firma-username", children: userName }), firmaGuardada ? (
                                // Renderizar la firma como imagen
                                _jsx("img", { src: firma, alt: "Firma del usuario", className: "RegisterCotizacionOrden-firma-img" })) : (
                                // Mensaje si no hay firma disponible
                                _jsx("p", { className: "RegisterCotizacionOrden-no-firma-message", children: "Este usuario a\u00FAn no cuenta con una firma. Por favor, dir\u00EDjase al apartado \"Mi Perfil\" y cree su firma." }))] }), _jsx("button", { className: "RegisterCotizacionOrden-register-button", disabled: loading, type: 'submit', children: loading ? 'Cargando...' : 'Registrar Cotización' }), _jsx("button", { className: "RegisterCotizacionOrden-cancel-button", type: "button", onClick: onCancel, disabled: loading, children: "Cancelar" }), error && _jsx("p", { className: "RegisterCotizacionOrden-error-message", children: error })] })] }) }));
};
export default RegisterCotizacionOrden;
