// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SolicitudesServicioPagination-pagination-controls {
    display: flex;
    position: relative;
    height: 100%;
    top: 0;
    left: 0%;
    align-items: center;
    justify-content: center;
    padding: 0.5vh 1.5965939329430547vw 0.5vh 0.2660989888238425vw;
}

.SolicitudesServicioPagination-IconButton {
  margin: 0 1vw; 
  z-index: 1;
}

.SolicitudesServicioPagination-ArrowBackIosNewIcon,
.SolicitudesServicioPagination-ArrowForwardIosIcon {
  color: #4F7A76;
}

.SolicitudesServicioPagination-ArrowBackIosNewIcon.inactive,
.SolicitudesServicioPagination-ArrowForwardIosIcon.inactive {
  color: #d9d9d9;
}

.SolicitudesServicioPagination-page-number {
  font-size: 1vw;
  margin: 0 1vw;
  color: #000000;
}

.SolicitudesServicioPagination-page-number-value{
  color: #4F7A76;
}`, "",{"version":3,"sources":["webpack://./src/modules/solicitudes/components/filters/styles/SolicitudesServicioPagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,MAAM;IACN,QAAQ;IACR,mBAAmB;IACnB,uBAAuB;IACvB,8DAA8D;AAClE;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".SolicitudesServicioPagination-pagination-controls {\n    display: flex;\n    position: relative;\n    height: 100%;\n    top: 0;\n    left: 0%;\n    align-items: center;\n    justify-content: center;\n    padding: 0.5vh 1.5965939329430547vw 0.5vh 0.2660989888238425vw;\n}\n\n.SolicitudesServicioPagination-IconButton {\n  margin: 0 1vw; \n  z-index: 1;\n}\n\n.SolicitudesServicioPagination-ArrowBackIosNewIcon,\n.SolicitudesServicioPagination-ArrowForwardIosIcon {\n  color: #4F7A76;\n}\n\n.SolicitudesServicioPagination-ArrowBackIosNewIcon.inactive,\n.SolicitudesServicioPagination-ArrowForwardIosIcon.inactive {\n  color: #d9d9d9;\n}\n\n.SolicitudesServicioPagination-page-number {\n  font-size: 1vw;\n  margin: 0 1vw;\n  color: #000000;\n}\n\n.SolicitudesServicioPagination-page-number-value{\n  color: #4F7A76;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
