var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from '../../../utils/config/axios.config';
export var getAllOrdenes = function (token, limit, page) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            limit: limit,
            page: page,
        },
    };
    return axios
        .get('/ordenes', options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var getOrdenById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .get("/ordenes/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var createOrden = function (token, ordenData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/ordenes', ordenData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
// Nueva función para crear órdenes en masivo
export var createMultipleOrdenes = function (token, ordenesData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .post('/ordenes/crear-masivo', ordenesData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateOrden = function (token, id, ordenData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put("/ordenes?id=".concat(id), ordenData, options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var updateMultipleOrdenes = function (token, ordenesData) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    return axios
        .put('/ordenes/actualizar-masivo', ordenesData, options)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        // Manejo de errores
        if (error.response) {
            var status_1 = error.response.status;
            if (status_1 === 500) {
                // Token inválido o expirado
                window.location.href = '/login';
            }
        }
        throw error;
    });
};
export var deleteOrdenById = function (token, id) {
    var options = {
        headers: {
            'x-access-token': token,
        },
        params: {
            id: id,
        },
    };
    return axios
        .delete("/ordenes/", options)
        .then(function (response) { return response.data; })
        .catch(handleError);
};
export var searchOrdenesByKeyword = function (token_1, keyword_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([token_1, keyword_1], args_1, true), void 0, function (token, keyword, limit, page) {
        var options, requestBody, response, error_1, status_2;
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    options = {
                        headers: {
                            'x-access-token': token,
                        },
                    };
                    requestBody = {
                        keyword: keyword,
                        limit: limit,
                        page: page,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/search/ordenes', requestBody, options)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_1 = _a.sent();
                    if (error_1.response) {
                        status_2 = error_1.response.status;
                        if (status_2 === 500) {
                            // Token inválido o expirado
                            // Redirigir al usuario a la página de inicio de sesión (/login)
                            window.location.href = '/login';
                        }
                    }
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    });
};
export var filterAdvancedOrdenes = function (token_1, filtersArray_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([token_1, filtersArray_1], args_1, true), void 0, function (token, filtersArray, limit, page) {
        var options, response, error_2, status_3;
        if (limit === void 0) { limit = 10; }
        if (page === void 0) { page = 1; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    options = {
                        headers: {
                            'x-access-token': token,
                        },
                        params: {
                            limit: limit,
                            page: page,
                        },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post('/advanced-filters-ordenes', filtersArray, options)];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_2 = _a.sent();
                    if (error_2.response) {
                        status_3 = error_2.response.status;
                        if (status_3 === 500) {
                            // Token inválido o expirado
                            // Redirigir al usuario a la página de inicio de sesión (/login)
                            window.location.href = '/login';
                        }
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
export var generateGetPresignedUrlsForOrdenImages = function (token, objectKeys) {
    var options = {
        headers: {
            'x-access-token': token,
        },
    };
    // console.log('Sending keys to generate presigned URLs:', objectKeys);
    return axios
        .post('/ordenes/generate-presigned-urls-get-ordenes-img', { keys: objectKeys }, options)
        .then(function (response) {
        // console.log('Received presigned URLs:', response.data.urls);
        return response.data;
    })
        .catch(function (error) {
        // console.error('Error fetching presigned URLs:', error);
        // throw error;
    });
};
export var generatePresignedUrlForFirmaEntrega = function (token, fileName) { return __awaiter(void 0, void 0, void 0, function () {
    var options, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                options = {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        fileName: fileName,
                    },
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/ordenes/generate-presigned-url-for-firma-entrega', options)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data.url];
            case 3:
                error_3 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var generatePresignedUrlForFirmaRecibe = function (token, fileName) { return __awaiter(void 0, void 0, void 0, function () {
    var options, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                options = {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        fileName: fileName,
                    },
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/ordenes/generate-presigned-url-for-firma-recibe', options)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data.url];
            case 3:
                error_4 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var generateGetPresignedUrlForFirmaOrden = function (token, key) { return __awaiter(void 0, void 0, void 0, function () {
    var options, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                options = {
                    headers: {
                        'x-access-token': token,
                    },
                    params: {
                        key: key,
                    },
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axios.get('/ordenes/generate-get-presigned-url-for-firma-orden', options)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data.url];
            case 3:
                error_5 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
// Función para manejar errores de las respuestas axios
var handleError = function (error) {
    if (error.response) {
        var status_4 = error.response.status;
        if (status_4 === 500) {
            // window.location.href = '/login';
            //   console.log(error);
        }
    }
    //   throw error;
};
