// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActividadesVisitas-div {
    height: auto;
    width: 100%;
    position: absolute;
    top: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ActividadesVisitas-set-loading {
    height: auto;
    font-size: 0.83vw; /* 16px */
    width: 100%;
    position: absolute;
    top: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4F7A76;
}

.ActividadesVisitas-container {
    height: auto;
    width: 100%;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ActividadesVisitas-title {
    position: relative;
    top: 0;
    left: 0;
    height: 2.31vh; /* 25px */
    width: 15.63vw; /* 300px */
    font-size: 0.83vw; /* 16px */
    text-align: center;
    border-radius: 0.52vw; /* 10px */
    color: #4F7A76;
    background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/ordenes_servicios/styles/ActividadesVisitas.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB,EAAE,SAAS;IAC5B,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,cAAc,EAAE,SAAS;IACzB,cAAc,EAAE,UAAU;IAC1B,iBAAiB,EAAE,SAAS;IAC5B,kBAAkB;IAClB,qBAAqB,EAAE,SAAS;IAChC,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".ActividadesVisitas-div {\n    height: auto;\n    width: 100%;\n    position: absolute;\n    top: 97%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.ActividadesVisitas-set-loading {\n    height: auto;\n    font-size: 0.83vw; /* 16px */\n    width: 100%;\n    position: absolute;\n    top: 97%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: #4F7A76;\n}\n\n.ActividadesVisitas-container {\n    height: auto;\n    width: 100%;\n    position: absolute;\n    top: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.ActividadesVisitas-title {\n    position: relative;\n    top: 0;\n    left: 0;\n    height: 2.31vh; /* 25px */\n    width: 15.63vw; /* 300px */\n    font-size: 0.83vw; /* 16px */\n    text-align: center;\n    border-radius: 0.52vw; /* 10px */\n    color: #4F7A76;\n    background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
