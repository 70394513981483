import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/PreventivoCard.css';
var PreventivoCard = function (_a) {
    var preventivo = _a.preventivo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "PreventivoCard-box ".concat(isSelected ? 'PreventivoCard-selected' : '', "\n  }");
    return (_jsxs("li", { onClick: onClick, className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '102%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#4F7A76',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#4F7A76',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx("div", { className: "PreventivoCard-overlap", children: _jsx(FlakyOutlinedIcon, { className: "PreventivoCard-icon" }) }), _jsx("div", { className: "PreventivoCard-title", children: preventivo.title }), _jsx("div", { className: "PreventivoCard-id", children: preventivo._id }), _jsxs("div", { className: "PreventivoCard-div", children: [_jsx("div", { className: "PreventivoCard-codigo-title", children: "C\u00F3digo" }), _jsx("div", { className: "PreventivoCard-codigo-value", children: preventivo.codigo })] }), _jsx("div", { className: "PreventivoCard-separator" }), _jsxs("div", { className: "PreventivoCard-overlap-2", children: [_jsx("div", { className: "PreventivoCard-version-title", children: "Versi\u00F3n" }), _jsx("div", { className: "PreventivoCard-text-wrapper", children: preventivo.version })] }), _jsx("div", { className: "PreventivoCard-separator-2" }), _jsxs("div", { className: "PreventivoCard-overlap-3", children: [_jsx("div", { className: "PreventivoCard-fecha-title", children: "Fecha" }), _jsx("div", { className: "PreventivoCard-text-wrapper", children: preventivo.fecha })] })] }, preventivo._id));
};
export default PreventivoCard;
