import { jsx as _jsx } from "react/jsx-runtime";
import { View, Image, StyleSheet } from '@react-pdf/renderer';
var styles = StyleSheet.create({
    imagesSection: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    imageWrapper: {
        margin: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 200,
        height: 200,
    },
    sectionContent: {
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
    },
});
var OrdenesPDFImagesSection = function (_a) {
    var orden = _a.orden, imageUrls = _a.imageUrls;
    return (_jsx(View, { style: styles.imagesSection, children: imageUrls.map(function (url, index) { return (_jsx(View, { style: styles.imageWrapper, children: _jsx(Image, { src: url, style: styles.image }) }, index)); }) }));
};
export default OrdenesPDFImagesSection;
