// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaudalBombaPreventivoRegistrar-container {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .CaudalBombaPreventivoRegistrar-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .CaudalBombaPreventivoRegistrar-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 5%;
  }
  
  .CaudalBombaPreventivoRegistrar-table th,
  .CaudalBombaPreventivoRegistrar-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  .CaudalBombaPreventivoRegistrar-table th {
    background-color: #f4f4f4;
  }
  
  .CaudalBombaPreventivoRegistrar-input {
    width: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/visitas/components/visitas/styles/CaudalBombaPreventivoRegistrar.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;EACxB","sourcesContent":[".CaudalBombaPreventivoRegistrar-container {\n    margin: 0;\n    font-family: Arial, sans-serif;\n  }\n  \n  .CaudalBombaPreventivoRegistrar-title {\n    font-size: 1.5rem;\n    margin-bottom: 10px;\n    font-weight: bold;\n  }\n  \n  .CaudalBombaPreventivoRegistrar-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 5%;\n  }\n  \n  .CaudalBombaPreventivoRegistrar-table th,\n  .CaudalBombaPreventivoRegistrar-table td {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .CaudalBombaPreventivoRegistrar-table th {\n    background-color: #f4f4f4;\n  }\n  \n  .CaudalBombaPreventivoRegistrar-input {\n    width: 100%;\n    padding: 4px;\n    box-sizing: border-box;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
