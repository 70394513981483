import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from '@mui/material/Checkbox';
import './styles/CostosEquiposCard.css';
var CostosEquiposCard = function (_a) {
    var costoEquipo = _a.costoEquipo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "CostosEquiposCard-box ".concat(isSelected ? 'CostosEquiposCard-selected' : '');
    // Formateador de número como COP
    var formatCurrency = function (value) {
        if (value === undefined)
            return 'N/A';
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 2,
        }).format(value);
    };
    return (
    // <li key={costoEquipo._id} className={liClassName} onClick={onClick}>
    _jsxs("li", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation();
                    onSelectToggle();
                }, className: "CostosEquiposCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '30%',
                    left: '2%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5,
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#4F7A76',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#4F7A76',
                    },
                } })), _jsxs("div", { className: "CostosEquiposCard-row", children: [_jsx("div", { className: "CostosEquiposCard-column", children: _jsx("span", { className: "CostosEquiposCard-value", children: costoEquipo._id }) }), _jsx("div", { className: "CostosEquiposCard-column", children: _jsx("span", { className: "CostosEquiposCard-value", children: costoEquipo.nombre_tecnico || 'N/A' }) }), _jsx("div", { className: "CostosEquiposCard-column", children: _jsx("span", { className: "CostosEquiposCard-value", children: formatCurrency(costoEquipo.costo) }) }), _jsx("div", { className: "CostosEquiposCard-column", children: _jsx("span", { className: "CostosEquiposCard-value", children: costoEquipo.codigo || 'N/A' }) })] })] }, costoEquipo._id));
};
export default CostosEquiposCard;
