var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import "./styles/CaudalBombaPreventivoRegistrar.css";
var CaudalFlujometroPreventivoRegistrar = function (_a) {
    var onChange = _a.onChange;
    var initialMediciones = [
        [
            { calibrador: 1, patron: 0, error: 0 },
            { calibrador: 2.1, patron: 0, error: 0 },
            { calibrador: 4, patron: 0, error: 0 },
        ],
        [
            { calibrador: 2, patron: 0, error: 0 },
            { calibrador: 1, patron: 0, error: 0 },
            { calibrador: 1, patron: 0, error: 0 },
        ],
        [
            { calibrador: 2, patron: 0, error: 0 },
            { calibrador: 2, patron: 0, error: 0 },
            { calibrador: 2, patron: 0, error: 0 },
        ],
    ];
    var _b = useState(initialMediciones.map(function (table) {
        return table.map(function (row) { return (__assign(__assign({}, row), { patronInput: row.patron.toString() })); });
    })), mediciones = _b[0], setMediciones = _b[1];
    var handleInputChange = function (tableIndex, rowIndex, value) {
        if (/^\d*\.?\d*$/.test(value)) {
            var updatedMediciones = __spreadArray([], mediciones, true);
            updatedMediciones[tableIndex][rowIndex].patronInput = value;
            setMediciones(updatedMediciones);
        }
    };
    var handleInputBlur = function (tableIndex, rowIndex) {
        var updatedMediciones = __spreadArray([], mediciones, true);
        var rawValue = updatedMediciones[tableIndex][rowIndex].patronInput;
        var patronValue = rawValue === "" || rawValue === "." ? 0 : parseFloat(rawValue);
        var calibrador = updatedMediciones[tableIndex][rowIndex].calibrador;
        var error = calibrador ? ((calibrador - patronValue) / calibrador) * 100 : 0;
        updatedMediciones[tableIndex][rowIndex] = {
            calibrador: calibrador,
            patron: patronValue,
            error: +error.toFixed(2),
            patronInput: patronValue.toString(),
        };
        setMediciones(updatedMediciones);
        calculateTotals(updatedMediciones);
    };
    var calculateTotals = function (data) {
        // Flatten mediciones into a single array
        var flattenedMediciones = data.flat().map(function (_a) {
            var calibrador = _a.calibrador, patron = _a.patron, error = _a.error;
            return ({
                calibrador: calibrador,
                patron: patron,
                error: error,
            });
        });
        // Calculate averages per table
        var promediosMediciones = data.map(function (table, index) { return ({
            medicion: index + 1,
            error: parseFloat((table.reduce(function (sum, item) { return sum + item.error; }, 0) / table.length || 0).toFixed(2)),
        }); });
        // Calculate overall average
        var totalPromedio = parseFloat((promediosMediciones.reduce(function (sum, item) { return sum + item.error; }, 0) /
            promediosMediciones.length || 0).toFixed(2));
        // Pass structured data to onChange
        onChange({
            mediciones: flattenedMediciones,
            promedios: {
                mediciones: promediosMediciones,
                total: totalPromedio,
            },
        });
    };
    var calculateDiferencias = function () {
        var promediosPorTabla = mediciones.map(function (table) {
            return table.reduce(function (sum, row) { return sum + row.error; }, 0) / table.length;
        });
        var promedioGlobal = promediosPorTabla.reduce(function (sum, promedio) { return sum + promedio; }, 0) / promediosPorTabla.length;
        var diferencias = promediosPorTabla.map(function (promedio, index) { return ({
            medicion: index + 1,
            diferencia: +(promedio - promedioGlobal).toFixed(2),
        }); });
        return { diferencias: diferencias, promedioGlobal: promedioGlobal };
    };
    var _c = calculateDiferencias(), diferencias = _c.diferencias, promedioGlobal = _c.promedioGlobal;
    return (_jsxs("div", { className: "CaudalBombaPreventivoRegistrar-container", children: [mediciones.map(function (table, tableIndex) { return (_jsxs("table", { className: "CaudalBombaPreventivoRegistrar-table", children: [_jsxs("thead", { children: [_jsxs("tr", { children: [_jsx("th", { children: "Medici\u00F3n" }), _jsx("th", { children: "Caudal Calibrador (l/min)" }), _jsx("th", { colSpan: 2, children: "Patr\u00F3n" })] }), _jsxs("tr", { children: [_jsx("th", {}), _jsx("th", {}), _jsx("th", { children: "Caudal (l/min)" }), _jsx("th", { children: "Error (%)" })] })] }), _jsxs("tbody", { children: [table.map(function (row, rowIndex) { return (_jsxs("tr", { children: [_jsx("td", { children: rowIndex + 1 }), _jsx("td", { children: row.calibrador }), _jsx("td", { children: _jsx("input", { type: "text", value: row.patronInput, onChange: function (e) { return handleInputChange(tableIndex, rowIndex, e.target.value); }, onBlur: function () { return handleInputBlur(tableIndex, rowIndex); } }) }), _jsx("td", { children: row.error.toFixed(2) })] }, rowIndex)); }), _jsxs("tr", { children: [_jsx("td", { colSpan: 3, children: "Promedio" }), _jsx("td", { children: (table.reduce(function (sum, row) { return sum + row.error; }, 0) / table.length || 0).toFixed(2) })] })] })] }, tableIndex)); }), _jsxs("table", { className: "CaudalBombaPreventivoRegistrar-table", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Medici\u00F3n" }), _jsx("th", { children: "Diferencia Error (%)" })] }) }), _jsx("tbody", { children: diferencias.map(function (diff) { return (_jsxs("tr", { children: [_jsx("td", { children: diff.medicion }), _jsx("td", { children: diff.diferencia.toFixed(2) })] }, diff.medicion)); }) }), _jsx("tfoot", { children: _jsxs("tr", { children: [_jsx("td", { children: "Promedio Global" }), _jsx("td", { children: promedioGlobal.toFixed(2) })] }) })] })] }));
};
export default CaudalFlujometroPreventivoRegistrar;
