var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { getAllCostosEquipos } from '../../services/costosEquiposService'; // Servicio correspondiente
import '../../../../components/filters/styles/AdvancedFilters.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import FiltersInputsCostosEquiposComponent from './FiltersInputsCostosEquiposComponent';
import FiltersCamposToSelectCostosEquipos from './FiltersCamposToSelectCostosEquipos';
var AdvancedFiltersCostosEquipos = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onApplyFilters = _a.onApplyFilters, currentPage = _a.currentPage, limitPerPage = _a.limitPerPage;
    var _b = useState(function () {
        var savedFilters = sessionStorage.getItem('advancedFiltersCostosEquipos');
        return savedFilters ? JSON.parse(savedFilters) : [{ _id: '', nombre_tecnico: '', costo: '', codigo: '' }];
    }), filters = _b[0], setFilters = _b[1];
    var _c = useState(filters.map(function () { return false; })), loading = _c[0], setLoading = _c[1];
    var _d = useState(filters.map(function () { return null; })), results = _d[0], setResults = _d[1];
    var _e = useState(false), showViewButton = _e[0], setShowViewButton = _e[1];
    var _f = useState(true), isEditing = _f[0], setIsEditing = _f[1];
    var _g = useState([]), alerts = _g[0], setAlerts = _g[1];
    var _h = useState(false), showFieldSelector = _h[0], setShowFieldSelector = _h[1];
    var _j = useState(function () {
        var savedFields = sessionStorage.getItem('selectedFieldsCostosEquipos');
        return savedFields ? JSON.parse(savedFields) : ['ID', 'NOMBRE TECNICO', 'COSTO', 'CODIGO'];
    }), selectedFields = _j[0], setSelectedFields = _j[1];
    var token = useSessionStorage('sessionJWTToken');
    useEffect(function () {
        var savedFilters = sessionStorage.getItem('advancedFiltersCostosEquipos');
        if (savedFilters) {
            setFilters(JSON.parse(savedFilters));
        }
        var savedSelectedFields = sessionStorage.getItem('selectedFieldsCostosEquipos');
        if (savedSelectedFields) {
            setSelectedFields(JSON.parse(savedSelectedFields));
        }
    }, []);
    var handleAddFilter = function () {
        setFilters(__spreadArray(__spreadArray([], filters, true), [{ _id: '', nombre_tecnico: '', costo: '', codigo: '' }], false));
        setLoading(__spreadArray(__spreadArray([], loading, true), [false], false));
        setResults(__spreadArray(__spreadArray([], results, true), [null], false));
    };
    var handleFilterChange = function (index, field, value) {
        var newFilters = __spreadArray([], filters, true);
        newFilters[index][field] = value;
        setFilters(newFilters);
        sessionStorage.setItem('advancedFiltersCostosEquipos', JSON.stringify(newFilters));
    };
    var handleRemoveFilter = function (index) {
        var newFilters = __spreadArray([], filters, true);
        var newLoading = __spreadArray([], loading, true);
        var newResults = __spreadArray([], results, true);
        newFilters.splice(index, 1);
        newLoading.splice(index, 1);
        newResults.splice(index, 1);
        setFilters(newFilters);
        setLoading(newLoading);
        setResults(newResults);
    };
    var handleSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newLoading, newResults, allResults, result, costosEquipos, _loop_1, i, paginatedResults, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (filters.length === 0) {
                        setAlerts(__spreadArray(__spreadArray([], alerts, true), [
                            { id: Date.now(), message: 'Agregue al menos un filtro.', severity: 'info' },
                        ], false));
                        return [2 /*return*/];
                    }
                    sessionStorage.setItem('advancedFiltersCostosEquipos', JSON.stringify(filters));
                    newLoading = filters.map(function () { return true; });
                    newResults = filters.map(function () { return null; });
                    setLoading(newLoading);
                    setResults(newResults);
                    allResults = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getAllCostosEquipos(token)];
                case 2:
                    result = _a.sent();
                    costosEquipos = result.costosEquipos;
                    _loop_1 = function (i) {
                        var filter = filters[i];
                        var filteredCostosEquipos = costosEquipos.filter(function (costo) {
                            return selectedFields.every(function (field) {
                                var _a, _b;
                                if (field === 'ID')
                                    return !filter._id || costo._id === filter._id;
                                if (field === 'NOMBRE TECNICO')
                                    return !filter.nombre_tecnico || ((_a = costo.nombre_tecnico) === null || _a === void 0 ? void 0 : _a.includes(filter.nombre_tecnico));
                                if (field === 'COSTO')
                                    return !filter.costo || costo.costo === Number(filter.costo);
                                if (field === 'CODIGO')
                                    return !filter.codigo || ((_b = costo.codigo) === null || _b === void 0 ? void 0 : _b.includes(filter.codigo));
                                return true;
                            });
                        });
                        if (filteredCostosEquipos.length > 0) {
                            newResults[i] = true;
                            allResults.push.apply(allResults, filteredCostosEquipos);
                        }
                        else {
                            newResults[i] = false;
                        }
                    };
                    for (i = 0; i < filters.length; i++) {
                        _loop_1(i);
                    }
                    paginatedResults = allResults.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage);
                    onApplyFilters(paginatedResults, allResults.length);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error al obtener los costos equipos:', error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(newLoading.map(function () { return false; }));
                    setResults(newResults);
                    setShowViewButton(true);
                    setIsEditing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleView = function () {
        onClose();
    };
    var handleEdit = function () {
        setIsEditing(true);
        setShowViewButton(false);
        setResults(filters.map(function () { return null; }));
    };
    var handleCloseAlert = function (id) {
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
    };
    var handleFieldSelection = function (fields) {
        setSelectedFields(fields);
        sessionStorage.setItem('selectedFieldsCostosEquipos', JSON.stringify(fields));
        setShowFieldSelector(false);
    };
    return isOpen ? (_jsxs("div", { className: "AdvancedFilters-modal-overlay", children: [_jsx("div", { className: "AdvancedFilters-modal-content", children: _jsx("div", { className: "AdvancedFilters-advanced-filters", children: _jsxs("div", { className: "AdvancedFilters-div", children: [_jsxs("div", { className: "AdvancedFilters-overlap", children: [_jsx("header", { className: "AdvancedFilters-header", children: _jsx("div", { className: "AdvancedFilters-overlap-group", children: _jsx("div", { className: "AdvancedFilters-filter-title", children: "FILTRAR COSTOS EQUIPOS" }) }) }), isEditing && (_jsx(AddCircleIcon, { className: "AdvancedFilters-filter-add-icon", onClick: handleAddFilter })), _jsx(FilterListIcon, { className: "AdvancedFilters-options-icon", onClick: function () { return setShowFieldSelector(true); } })] }), filters.length > 0 && (_jsx(FiltersInputsCostosEquiposComponent, { filters: filters, selectedFields: selectedFields, handleFilterChange: handleFilterChange, handleRemoveFilter: handleRemoveFilter, loading: loading, results: results, isEditing: isEditing })), isEditing ? (_jsx("button", { className: "AdvancedFilters-cancel-button", onClick: onClose, children: "Cancelar" })) : (_jsx("button", { className: "AdvancedFilters-edit-button", onClick: handleEdit, children: "Editar" })), showViewButton ? (_jsx("button", { className: "AdvancedFilters-view-button", onClick: handleView, children: "Ver" })) : (_jsx("button", { className: "AdvancedFilters-send-button", onClick: handleSearch, children: "Enviar" }))] }) }) }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert }), showFieldSelector && (_jsx(FiltersCamposToSelectCostosEquipos, { onSubmit: handleFieldSelection, onClose: function () { return setShowFieldSelector(false); }, initialSelectedFields: selectedFields }))] })) : null;
};
export default AdvancedFiltersCostosEquipos;
