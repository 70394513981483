var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { sendCotizacionEmail } from '../../../cotizaciones/services/cotizacionesService';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import './styles/SendCotizacionEmail.css';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import { CircularProgress } from '@mui/material';
var SendCotizacionEmail = function (_a) {
    var onClose = _a.onClose, pdfBlob = _a.pdfBlob, cotizacion = _a.cotizacion, generatePDF = _a.generatePDF;
    var _b = useState(''), emails = _b[0], setEmails = _b[1];
    var _c = useState(false), isSending = _c[0], setIsSending = _c[1];
    var token = useSessionStorage('sessionJWTToken');
    var validateEmails = function (emailString) {
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailString.split(',').every(function (email) { return emailPattern.test(email.trim()); });
    };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var finalPdfBlob, file, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (!emails || !validateEmails(emails)) {
                        alert("Please enter valid email addresses.");
                        return [2 /*return*/];
                    }
                    finalPdfBlob = pdfBlob;
                    if (!!finalPdfBlob) return [3 /*break*/, 2];
                    setIsSending(true);
                    return [4 /*yield*/, generatePDF()];
                case 1:
                    finalPdfBlob = _a.sent();
                    if (!finalPdfBlob) {
                        alert("Failed to generate PDF. Please try again.");
                        setIsSending(false);
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    file = new File([finalPdfBlob], "cotizacion-".concat(cotizacion._id, ".pdf"), { type: 'application/pdf' });
                    setIsSending(true);
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, 6, 7]);
                    return [4 /*yield*/, sendCotizacionEmail(token, { emails: emails, subject: "Cotizaci\u00F3n PDF - Cliente: ".concat(cotizacion.id_cliente.client_name), file: file })];
                case 4:
                    _a.sent();
                    alert('Email sent successfully');
                    onClose();
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _a.sent();
                    console.error('Error sending email:', error_1);
                    alert('Error sending email');
                    return [3 /*break*/, 7];
                case 6:
                    setIsSending(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsxs("form", { className: "SendCotizacionEmail-email-form", onSubmit: handleSubmit, children: [_jsx("div", { className: "SendCotizacionEmail-email-text", children: "Ingrese las direcciones de email, separadas por comas:" }), _jsx("input", { className: "SendCotizacionEmail-email-input", type: "text", value: emails, onChange: function (e) { return setEmails(e.target.value); }, placeholder: "Enter emails separated by commas", disabled: isSending }), _jsx("button", { className: "SendCotizacionEmail-send-button", type: "submit", disabled: isSending, children: isSending ? (_jsx(CircularProgress, { size: 20, style: { color: '#4F7A76', position: 'relative' } })) : (_jsx(SendIcon, { className: "SendCotizacionEmail-send-icon" })) }), _jsx(CancelIcon, { className: "SendCotizacionEmail-cancel-icon", onClick: onClose })] }) }));
};
export default SendCotizacionEmail;
