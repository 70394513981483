import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Checkbox from '@mui/material/Checkbox';
import './styles/ContratoCard.css';
import { differenceInDays, parseISO } from 'date-fns';
var ContratoCard = function (_a) {
    var contrato = _a.contrato, cliente = _a.cliente, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    // Calculate "Días Vigencia" and "Días Restantes"
    var calculateDiasVigencia = function () {
        if (!contrato.fecha_inicio)
            return { activeDays: 'N/A', remainingDays: 'N/A', totalDays: 'N/A' }; // If no start date, return 'N/A'
        // Get the current date and format it to match Bogotá, Colombia timezone (no timezone offset adjustments)
        var now = new Date();
        var formattedDate = new Date("".concat(now.getFullYear(), "-").concat((now.getMonth() + 1).toString().padStart(2, '0'), "-").concat(now.getDate().toString().padStart(2, '0'), " ").concat(now.getHours().toString().padStart(2, '0'), ":").concat(now.getMinutes().toString().padStart(2, '0'), ":").concat(now.getSeconds().toString().padStart(2, '0')));
        var startDate = parseISO(contrato.fecha_inicio);
        var endDate = contrato.fecha_finalizacion ? parseISO(contrato.fecha_finalizacion) : formattedDate;
        // Calculate the active days
        var activeDays = differenceInDays(formattedDate, startDate);
        // Calculate the remaining days
        var remainingDays = contrato.fecha_finalizacion ? differenceInDays(endDate, formattedDate) : 'N/A';
        // Calculate the total days of the contract
        var totalDays = contrato.fecha_finalizacion ? differenceInDays(endDate, startDate) : 'N/A';
        return { activeDays: activeDays, remainingDays: remainingDays, totalDays: totalDays };
    };
    // Calculate "Días Vigencia" using the corresponding function
    var _b = calculateDiasVigencia(), activeDays = _b.activeDays, remainingDays = _b.remainingDays, totalDays = _b.totalDays;
    // Determine the className for "ContratoCard-servicio-title" based on "Días Restantes"
    var titleClassName = 'ContratoCard-servicio-title';
    if (typeof remainingDays === 'number' && remainingDays < 0) {
        titleClassName += ' ContratoCard-red'; // Apply red class if remainingDays is negative
    }
    else if (typeof remainingDays === 'number' && typeof totalDays === 'number') {
        var remainingPercentage = (remainingDays / totalDays) * 100;
        if (remainingPercentage <= 20) {
            titleClassName += ' ContratoCard-red'; // Apply red class if less than or equal to 20% remaining
        }
        else if (remainingPercentage > 20 && remainingPercentage <= 50) {
            titleClassName += ' ContratoCard-yellow'; // Apply yellow class if between 21% and 50% remaining
        }
        else {
            titleClassName += ' ContratoCard-green'; // Apply green class if more than 50% remaining
        }
    }
    // Conditional className for the li based on "Días Restantes"
    var liClassName = "ContratoCard-box ".concat(isSelected ? 'ContratoCard-selected' : '');
    return (_jsxs("li", { className: liClassName, children: [_jsx("div", { className: "ContratoCard-overlap", children: _jsxs("header", { className: "ContratoCard-header", children: [_jsx("div", { className: titleClassName, onClick: onClick, children: contrato ? contrato.numero : 'N/A' }), _jsxs("div", { className: "ContratoCard-creation-date", children: ["Incio: ", contrato ? contrato.fecha_inicio : 'N/A', " - Fin: ", contrato ? contrato.fecha_finalizacion : 'N/A'] }), _jsxs("div", { className: "ContratoCard-oid-contrato", children: ["ID: ", contrato ? contrato._id : 'N/A'] }), _jsx("div", { className: "ContratoCard-username", children: contrato.id_creador ? contrato.id_creador.username : 'N/A' }), _jsx(CalendarTodayOutlinedIcon, { className: "ContratoCard-update-icon" }), _jsx(AddOutlinedIcon, { className: "ContratoCard-add-icon" }), selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                                e.stopPropagation(); // Prevent the click on the checkbox from triggering other events
                                onSelectToggle(); // Trigger the function to add or remove the contract from the selection
                            }, className: "ContratoCard-select-checkbox", sx: {
                                position: 'absolute',
                                top: '10px',
                                left: '90%',
                                width: '20px',
                                height: '20px',
                                cursor: 'pointer',
                                zIndex: 5, // Ensure it is on top of other elements
                                transform: 'scale(1.5)',
                                '&.Mui-checked': {
                                    color: '#4F7A76',
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 221, 250, 0.1)',
                                    borderRadius: '50%',
                                },
                                '& .MuiSvgIcon-root': {
                                    fill: '#4F7A76',
                                },
                                '& input': {
                                    zIndex: 3, // Adjust the z-index of the input in the DOM
                                },
                            } }))] }) }), _jsxs("div", { className: "ContratoCard-equipo-info", children: [_jsx("div", { className: "ContratoCard-info-title", children: "CLIENTE" }), _jsx("div", { className: "ContratoCard-equipo-id", children: cliente ? cliente._id : 'N/A' }), _jsx("div", { className: "ContratoCard-text-wrapper", children: cliente ? cliente.client_name : 'ClientName' }), _jsx("div", { className: "ContratoCard-oid-equipo", children: "NIT" }), _jsx("div", { className: "ContratoCard-serialnumber", children: cliente ? cliente.client_nit : 'N/A' }), _jsx("div", { className: "ContratoCard-ubicacion-title", children: "TIPO CLIENTE" }), _jsx("div", { className: "ContratoCard-ubicacion-value", children: cliente ? cliente.id_client_tipo.tipo : 'N/A' })] }), _jsx("div", { className: "ContratoCard-separator" }), _jsxs("div", { className: "ContratoCard-sede-info", children: [_jsx("div", { className: "ContratoCard-info-sede-title", children: "RESPONSABLE" }), _jsx("div", { className: 'ContratoCard-sede-id', children: contrato.id_responsable ? contrato.id_responsable._id : 'N/A' }), _jsx("div", { className: "ContratoCard-text-wrapper", children: contrato.id_responsable ? contrato.id_responsable.name : 'N/A' }), _jsx("div", { className: "ContratoCard-oid-sede", children: "TIPO DE CONTRATO" }), _jsx("div", { className: 'ContratoCard-cliente-id', children: contrato.id_contrato_tipo ? contrato.id_contrato_tipo._id : 'N/A' }), _jsx("p", { className: "ContratoCard-cliente-name", children: contrato.id_contrato_tipo ? contrato.id_contrato_tipo.tipo : 'N/A' })] }), _jsxs("div", { className: 'ContratoCard-solicitud-info', children: [_jsx("div", { className: "ContratoCard-solicitud-title", children: "D\u00CDAS VIGENTES" }), _jsxs("div", { className: "ContratoCard-solicitud-date ".concat(typeof remainingDays === 'number' && remainingDays < 0 ? 'ContratoCard-negative' : ''), children: ["Activos: ", activeDays, " - Restantes: ", remainingDays] })] })] }, contrato._id));
};
export default ContratoCard;
