var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import './styles/OrdenesActionsComponent.css';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { exportOrdenesToExcel } from '../../services/exportOrdenesExcelService';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { generateGetPresignedUrlsForOrdenImages, generateGetPresignedUrlForFirmaOrden } from '../../services/ordenesService';
import OrdenAbiertaCorrectivoPDF from '../ordenesPDF/OrdenAbiertaCorrectivoPDF/OrdenAbiertaCorrectivoPDF';
import OrdenAbiertaPreventivoPDF from '../ordenesPDF/OrdenAbiertaPreventivoPDF/OrdenAbiertaPreventivoPDF';
import OrdenAnuladaCorrectivoPDF from '../ordenesPDF/OrdenAnuladaCorrectivoPDF/OrdenAnuladaCorrectivoPDF';
import OrdenAnuladaPreventivoPDF from '../ordenesPDF/OrdenAnuladaPreventivoPDF/OrdenAnuladaPreventivoPDF';
import OrdenCerradaCorrectivoPDF from '../ordenesPDF/OrdenCerradaCorrectivoPDF/OrdenCerradaCorrectivoPDF';
import OrdenCerradaPreventivoPDF from '../ordenesPDF/OrdenCerradaPreventivoPDF/OrdenCerradaPreventivoPDF';
import { CircularProgress } from '@mui/material';
import { getAllCotizaciones } from '../../../cotizaciones/services/cotizacionesService';
import UpdateOrdenesServicioEstadoMassive from './UpdateOrdenesServicioEstadoMassive';
var OrdenesActionsComponent = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, ordenes = _a.ordenes, selectMode = _a.selectMode, ordenesSelected = _a.ordenesSelected;
    var _b = useState(''), selectedAction = _b[0], setSelectedAction = _b[1];
    var _c = useState([]), alerts = _c[0], setAlerts = _c[1];
    var _d = useState(new Map()), cotizaciones = _d[0], setCotizaciones = _d[1];
    var _e = useState(new Map()), imageUrls = _e[0], setImageUrls = _e[1];
    var _f = useState(new Map()), firmaUrls = _f[0], setFirmaUrls = _f[1];
    var _g = useState(false), isLoading = _g[0], setIsLoading = _g[1]; // Estado para controlar la carga
    var _h = useState(false), showUpdateMassiveForm = _h[0], setShowUpdateMassiveForm = _h[1]; // Mostrar/ocultar formulario de actualización masiva
    useEffect(function () {
        var fetchCotizaciones = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, allCotizaciones_1, cotMap_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = sessionStorage.getItem('sessionJWTToken') || '';
                        return [4 /*yield*/, getAllCotizaciones(token)];
                    case 1:
                        allCotizaciones_1 = _a.sent();
                        cotMap_1 = new Map();
                        ordenes.forEach(function (orden) {
                            var relacionadas = allCotizaciones_1.filter(function (cot) {
                                return cot.id_orden && cot.id_orden._id === orden._id && cot.id_estado.estado === 'Aprobada';
                            });
                            cotMap_1.set(orden._id, relacionadas);
                        });
                        setCotizaciones(cotMap_1);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching cotizaciones:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchCotizaciones();
    }, [ordenes]);
    useEffect(function () {
        if (ordenesSelected) {
            // Solo ejecutamos las funciones si hay órdenes seleccionadas
            var fetchPresignedUrls = function () { return __awaiter(void 0, void 0, void 0, function () {
                var token, imgMap, firmaMap, _i, ordenes_1, orden, keys, response, error_2, firmaUrls_1, url, err_1, url, err_2;
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            token = sessionStorage.getItem('sessionJWTToken') || '';
                            imgMap = new Map();
                            firmaMap = new Map();
                            _i = 0, ordenes_1 = ordenes;
                            _c.label = 1;
                        case 1:
                            if (!(_i < ordenes_1.length)) return [3 /*break*/, 17];
                            orden = ordenes_1[_i];
                            if (!(orden.imagenes_imprimir && Array.isArray(orden.imagenes_imprimir) && orden.imagenes_imprimir.length > 0)) return [3 /*break*/, 6];
                            keys = orden.imagenes_imprimir.map(function (img) {
                                var urlPath = new URL(img.image).pathname;
                                return urlPath.startsWith('/') ? urlPath.substring(1) : urlPath;
                            });
                            _c.label = 2;
                        case 2:
                            _c.trys.push([2, 4, , 5]);
                            return [4 /*yield*/, generateGetPresignedUrlsForOrdenImages(token, keys)];
                        case 3:
                            response = _c.sent();
                            if (response && response.urls) {
                                imgMap.set(orden._id, response.urls);
                            }
                            else {
                                imgMap.set(orden._id, []);
                            }
                            return [3 /*break*/, 5];
                        case 4:
                            error_2 = _c.sent();
                            console.error('Error fetching presigned URLs for images:', error_2);
                            return [3 /*break*/, 5];
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            imgMap.set(orden._id, []); // Si no hay imágenes, establecer un array vacío
                            _c.label = 7;
                        case 7:
                            firmaUrls_1 = { entrega: '', recibe: '' };
                            if (!((_a = orden.entrega) === null || _a === void 0 ? void 0 : _a.firma_entrega)) return [3 /*break*/, 11];
                            _c.label = 8;
                        case 8:
                            _c.trys.push([8, 10, , 11]);
                            return [4 /*yield*/, generateGetPresignedUrlForFirmaOrden(token, orden.entrega.firma_entrega)];
                        case 9:
                            url = _c.sent();
                            firmaUrls_1.entrega = url;
                            return [3 /*break*/, 11];
                        case 10:
                            err_1 = _c.sent();
                            console.error("Error fetching firma entrega URL:", err_1);
                            return [3 /*break*/, 11];
                        case 11:
                            if (!((_b = orden.recibe) === null || _b === void 0 ? void 0 : _b.firma_recibe)) return [3 /*break*/, 15];
                            _c.label = 12;
                        case 12:
                            _c.trys.push([12, 14, , 15]);
                            return [4 /*yield*/, generateGetPresignedUrlForFirmaOrden(token, orden.recibe.firma_recibe)];
                        case 13:
                            url = _c.sent();
                            firmaUrls_1.recibe = url;
                            return [3 /*break*/, 15];
                        case 14:
                            err_2 = _c.sent();
                            console.error("Error fetching firma recibe URL:", err_2);
                            return [3 /*break*/, 15];
                        case 15:
                            firmaMap.set(orden._id, firmaUrls_1);
                            _c.label = 16;
                        case 16:
                            _i++;
                            return [3 /*break*/, 1];
                        case 17:
                            setImageUrls(imgMap);
                            setFirmaUrls(firmaMap);
                            return [2 /*return*/];
                    }
                });
            }); };
            fetchPresignedUrls();
        }
    }, [ordenesSelected, ordenes]);
    var handleGeneratePDFs = function () { return __awaiter(void 0, void 0, void 0, function () {
        var zip, _i, ordenes_2, orden, pdfDoc, cotizacionesRelacionadas, urlsImagenes, urlsFirmas, blob, zipBlob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true); // Inicia la carga
                    zip = new JSZip();
                    _i = 0, ordenes_2 = ordenes;
                    _a.label = 1;
                case 1:
                    if (!(_i < ordenes_2.length)) return [3 /*break*/, 4];
                    orden = ordenes_2[_i];
                    pdfDoc = void 0;
                    cotizacionesRelacionadas = cotizaciones.get(orden._id) || [];
                    urlsImagenes = imageUrls.get(orden._id) || [];
                    urlsFirmas = firmaUrls.get(orden._id) || { entrega: '', recibe: '' };
                    if (orden.id_orden_estado.estado === "Abierta") {
                        if (orden.id_solicitud_servicio.id_servicio.servicio === "Correctivo") {
                            pdfDoc = pdf(_jsx(OrdenAbiertaCorrectivoPDF, { orden: orden, cotizaciones: cotizacionesRelacionadas, includeHeader: true, includeDetails: true, imageUrls: urlsImagenes, firmaUrls: urlsFirmas }));
                        }
                        else {
                            pdfDoc = pdf(_jsx(OrdenAbiertaPreventivoPDF, { orden: orden, cotizaciones: cotizacionesRelacionadas, includeHeader: true, includeDetails: true, imageUrls: urlsImagenes, firmaUrls: urlsFirmas }));
                        }
                    }
                    else if (orden.id_orden_estado.estado === "Anulada") {
                        if (orden.id_solicitud_servicio.id_servicio.servicio === "Correctivo") {
                            pdfDoc = pdf(_jsx(OrdenAnuladaCorrectivoPDF, { orden: orden, cotizaciones: cotizacionesRelacionadas, includeHeader: true, includeDetails: true, imageUrls: urlsImagenes, firmaUrls: urlsFirmas }));
                        }
                        else {
                            pdfDoc = pdf(_jsx(OrdenAnuladaPreventivoPDF, { orden: orden, cotizaciones: cotizacionesRelacionadas, includeHeader: true, includeDetails: true, imageUrls: urlsImagenes, firmaUrls: urlsFirmas }));
                        }
                    }
                    else if (orden.id_orden_estado.estado === "Cerrada") {
                        if (orden.id_solicitud_servicio.id_servicio.servicio === "Correctivo") {
                            pdfDoc = pdf(_jsx(OrdenCerradaCorrectivoPDF, { orden: orden, cotizaciones: cotizacionesRelacionadas, includeHeader: true, includeDetails: true, imageUrls: urlsImagenes, firmaUrls: urlsFirmas }));
                        }
                        else {
                            pdfDoc = pdf(_jsx(OrdenCerradaPreventivoPDF, { orden: orden, cotizaciones: cotizacionesRelacionadas, includeHeader: true, includeDetails: true, imageUrls: urlsImagenes, firmaUrls: urlsFirmas }));
                        }
                    }
                    else {
                        return [3 /*break*/, 3]; // Si la orden no está en un estado válido, la omitimos
                    }
                    return [4 /*yield*/, pdfDoc.toBlob()];
                case 2:
                    blob = _a.sent();
                    zip.file("".concat(orden._id, ".pdf"), blob);
                    _a.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [4 /*yield*/, zip.generateAsync({ type: 'blob' })];
                case 5:
                    zipBlob = _a.sent();
                    saveAs(zipBlob, 'ordenes.zip');
                    setIsLoading(false); // Finaliza la carga
                    return [2 /*return*/];
            }
        });
    }); };
    var handleContinue = function () {
        if (selectedAction === '') {
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Seleccione una opción antes de continuar',
                    severity: 'warning'
                }
            ], false); });
            return;
        }
        if (selectedAction === 'descargarExcel') {
            // No se requiere validación de `ordenesSelected` para la opción Excel
            exportOrdenesToExcel(ordenes);
            setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                {
                    id: new Date().getTime(),
                    message: 'Órdenes exportadas a Excel exitosamente',
                    severity: 'success'
                }
            ], false); });
            onClose();
        }
        if (selectedAction === 'exportarZip') {
            if (!ordenesSelected) {
                setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                    {
                        id: new Date().getTime(),
                        message: 'No se han seleccionado Órdenes a exportar',
                        severity: 'warning'
                    }
                ], false); });
                return;
            }
            handleGeneratePDFs().then(function () {
                setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                    {
                        id: new Date().getTime(),
                        message: 'Órdenes exportadas satisfactoriamente',
                        severity: 'success'
                    }
                ], false); });
                onClose();
            });
        }
        if (selectedAction === 'cerrarAnularReabrir') {
            if (!ordenesSelected) {
                setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                    {
                        id: new Date().getTime(),
                        message: 'No hay Órdenes Seleccionadas',
                        severity: 'warning'
                    }
                ], false); });
                return;
            }
            setShowUpdateMassiveForm(true); // Mostrar el formulario de actualización masiva
        }
    };
    if (showUpdateMassiveForm) {
        return (_jsx(UpdateOrdenesServicioEstadoMassive, { ordenes: ordenes, onCancel: function () { return setShowUpdateMassiveForm(false); } }));
    }
    var handleCloseAlert = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    return (isOpen ? (_jsx("div", { className: "OrdenesActionsComponent-modal-overlay", children: _jsxs("div", { className: "OrdenesActionsComponent-modal-content", children: [_jsx("h2", { className: "OrdenesActionsComponent-title", children: "ACCIONES - ORDENES" }), _jsxs("select", { className: "OrdenesActionsComponent-select", value: selectedAction, onChange: function (e) { return setSelectedAction(e.target.value); }, disabled: isLoading, children: [_jsx("option", { value: "", disabled: true, children: "Seleccionar..." }), _jsx("option", { value: "descargarExcel", children: "Descargar Excel" }), _jsx("option", { value: "exportarZip", children: "Exportar ZIP/RAR" }), _jsx("option", { value: "cerrarAnularReabrir", disabled: !ordenesSelected, children: "Cerrar/Anular/Reabrir ODS Masivo" }), _jsx("option", { value: "", disabled: true, children: "Imprimir" })] }), _jsxs("div", { className: 'OrdenesActionsComponent-buttons-div', children: [_jsx("button", { className: "OrdenesActionsComponent-continue-button", onClick: handleContinue, disabled: isLoading, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : 'Continuar' }), _jsx("button", { className: "OrdenesActionsComponent-cancel-button", onClick: onClose, disabled: isLoading, children: "Cancelar" })] }), _jsx(MuiAlertComponent, { alerts: alerts, onClose: handleCloseAlert })] }) })) : null);
};
export default OrdenesActionsComponent;
