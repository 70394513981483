// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchOrdenes-search-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0.53vh 0.26vw 0.53vh 0.26vw;
}

.SearchOrdenes-search-form {
  display: flex;
  align-items: center;
  position: relative;
}

.SearchOrdenes-search-icon-button {
  color: #4F7A76;
  z-index: 1;
}

.SearchOrdenes-search-icon {
  height: 2.6vh !important;
  width: 1.3vw !important;
  color: #4F7A76;
  cursor: pointer;
  transition: transform 0.4s ease;
}

.SearchOrdenes-search-input {
  padding: 1.06vh 0.79vw;
  color: #000000;
  text-transform: uppercase;
  font-size: 0.85vw;
  font-weight: bold;
  letter-spacing: 0.10vw;
  border: none;
  border-radius: 1rem;
  background: #d9d9d9;
  outline: none;
  width: 0;
  opacity: 0;
  transition: width 0.4s ease, opacity 0.4s ease;
  position: absolute;
  right: 2.12vw; /* Adjust the value to align the input to the left of the icon */
}

.SearchOrdenes-search-input.visible {
  width: 12.772751463544438vw; /* Adjust the width as needed */
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/modules/ordenes/components/filters/styles/SearchOrdenes.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,oCAAoC;AACxC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,cAAc;EACd,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,yBAAyB;EACzB,iBAAiB;EACjB,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,QAAQ;EACR,UAAU;EACV,8CAA8C;EAC9C,kBAAkB;EAClB,aAAa,EAAE,gEAAgE;AACjF;;AAEA;EACE,2BAA2B,EAAE,+BAA+B;EAC5D,UAAU;AACZ","sourcesContent":[".SearchOrdenes-search-container {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    position: relative;\n    height: 100%;\n    padding: 0.53vh 0.26vw 0.53vh 0.26vw;\n}\n\n.SearchOrdenes-search-form {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.SearchOrdenes-search-icon-button {\n  color: #4F7A76;\n  z-index: 1;\n}\n\n.SearchOrdenes-search-icon {\n  height: 2.6vh !important;\n  width: 1.3vw !important;\n  color: #4F7A76;\n  cursor: pointer;\n  transition: transform 0.4s ease;\n}\n\n.SearchOrdenes-search-input {\n  padding: 1.06vh 0.79vw;\n  color: #000000;\n  text-transform: uppercase;\n  font-size: 0.85vw;\n  font-weight: bold;\n  letter-spacing: 0.10vw;\n  border: none;\n  border-radius: 1rem;\n  background: #d9d9d9;\n  outline: none;\n  width: 0;\n  opacity: 0;\n  transition: width 0.4s ease, opacity 0.4s ease;\n  position: absolute;\n  right: 2.12vw; /* Adjust the value to align the input to the left of the icon */\n}\n\n.SearchOrdenes-search-input.visible {\n  width: 12.772751463544438vw; /* Adjust the width as needed */\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
