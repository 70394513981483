var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import '../RepuestosEquipos/forms/styles/RegisterRepuestoEquipoForm.css'; // Usamos el mismo CSS de Register
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { updateRepuestoEquipo, getAllRepuestosEquipos } from '../../services/repuestosEquiposService';
import { getAllClients } from '../../../users/services/clientsService';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlertComponent from '../../../../components/MuiAlertsComponent';
import { getAllCostosRepuestos } from '../../../costos/services/costosRepuestosService';
var EditRepuestoEquipoButton = function (_a) {
    var _b, _c, _d, _e, _f;
    var repuestoEquipoId = _a.repuestoEquipoId, onEditSuccess = _a.onEditSuccess, onCancel = _a.onCancel, initialData = _a.initialData;
    var loggedIn = useSessionStorage('sessionJWTToken');
    var _g = useState(__assign(__assign({}, initialData), { id_cliente: initialData.id_cliente ? initialData.id_cliente._id : '', id_contrato: initialData.id_contrato ? initialData.id_contrato._id : '', id_costo_repuesto: initialData.id_costo_repuesto ? initialData.id_costo_repuesto._id : '' })), repuestoEquipoData = _g[0], setRepuestoEquipoData = _g[1];
    var _h = useState(null), repuestoAvailable = _h[0], setRepuestoAvailable = _h[1];
    var _j = useState(false), repuestoCheckLoading = _j[0], setRepuestoCheckLoading = _j[1];
    var _k = useState(initialData.id_cliente ? initialData.id_cliente.client_name : ''), clientKeyword = _k[0], setClientKeyword = _k[1];
    var _l = useState([]), clientResults = _l[0], setClientResults = _l[1];
    var _m = useState([]), contratos = _m[0], setContratos = _m[1]; // Lista de contratos
    var _o = useState(false), isSubmitting = _o[0], setIsSubmitting = _o[1];
    var _p = useState([]), alerts = _p[0], setAlerts = _p[1];
    var formatCurrency = function (value) {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
        }).format(value);
    };
    var _q = useState(function () {
        return initialData.id_costo_repuesto
            ? "".concat(initialData.id_costo_repuesto.nombre_tecnico, " - ").concat(initialData.id_costo_repuesto.codigo, " - ").concat(formatCurrency(initialData.id_costo_repuesto.costo))
            : '';
    }), costoRepuestosKeyword = _q[0], setCostoRepuestosKeyword = _q[1];
    var _r = useState([]), costoRepuestosResults = _r[0], setCostoRepuestosResults = _r[1];
    var _s = useState(initialData.id_costo_repuesto || null), selectedCostoRepuesto = _s[0], setSelectedCostoRepuesto = _s[1];
    useEffect(function () {
        var fetchClients = function () { return __awaiter(void 0, void 0, void 0, function () {
            var results, filteredClients, selectedClient, contratosDisponibles, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getAllClients(loggedIn)];
                    case 1:
                        results = _a.sent();
                        filteredClients = results.clients.filter(function (client) {
                            return client.client_name.toLowerCase().includes(clientKeyword.toLowerCase());
                        });
                        setClientResults(filteredClients);
                        // Si hay un cliente seleccionado con contratos, también cargamos los contratos
                        if (repuestoEquipoData.id_cliente) {
                            selectedClient = results.clients.find(function (client) { return client._id === repuestoEquipoData.id_cliente; });
                            if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.ids_contratos) {
                                contratosDisponibles = selectedClient.ids_contratos.map(function (contrato) {
                                    var _a;
                                    return ({
                                        id: contrato._id,
                                        displayText: "Contrato de ".concat(((_a = contrato.id_contrato_tipo) === null || _a === void 0 ? void 0 : _a.tipo) || 'N/A', " - ").concat(contrato.numero || 'N/A', " - ").concat(contrato._id),
                                    });
                                });
                                setContratos(contratosDisponibles);
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching clients:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (clientKeyword.trim() || repuestoEquipoData.id_cliente) {
            fetchClients();
        }
        else {
            setClientResults([]);
        }
    }, [clientKeyword, loggedIn]);
    var handleSelectClient = function (client) {
        setRepuestoEquipoData(__assign(__assign({}, repuestoEquipoData), { id_cliente: client._id }));
        setClientKeyword(client.client_name);
        setClientResults([]);
        // Cargar los contratos asociados al cliente seleccionado
        if (client.ids_contratos) {
            var contratosDisponibles = client.ids_contratos
                .filter(function (contrato) { return contrato._id !== repuestoEquipoData.id_contrato; }) // Excluir el contrato actual
                .map(function (contrato) {
                var _a;
                return ({
                    id: contrato._id,
                    displayText: "Contrato de ".concat(((_a = contrato.id_contrato_tipo) === null || _a === void 0 ? void 0 : _a.tipo) || 'N/A', " - ").concat(contrato.numero || 'N/A', " - ").concat(contrato._id),
                });
            });
            setContratos(contratosDisponibles);
        }
        else {
            setContratos([]);
        }
    };
    useEffect(function () {
        var fetchCostosRepuestos = function () { return __awaiter(void 0, void 0, void 0, function () {
            var token, response, filteredCostos, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        token = loggedIn;
                        return [4 /*yield*/, getAllCostosRepuestos(token)];
                    case 1:
                        response = _a.sent();
                        filteredCostos = response.costosRepuestos.filter(function (costo) {
                            return costo.nombre_tecnico.toLowerCase().includes(costoRepuestosKeyword.toLowerCase());
                        });
                        setCostoRepuestosResults(filteredCostos);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error fetching costos repuestos:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (costoRepuestosKeyword.trim()) {
            fetchCostosRepuestos();
        }
        else {
            setCostoRepuestosResults([]);
        }
    }, [costoRepuestosKeyword, loggedIn]);
    var handleSelectCostoRepuesto = function (costoRepuesto) {
        setRepuestoEquipoData(function (prevData) { return (__assign(__assign({}, prevData), { id_costo_repuesto: costoRepuesto._id })); });
        setSelectedCostoRepuesto(costoRepuesto); // Guardar el repuesto seleccionado
        setCostoRepuestosKeyword("".concat(costoRepuesto.nombre_tecnico, " - ").concat(costoRepuesto.codigo, " - ").concat(formatCurrency(costoRepuesto.costo)));
        setCostoRepuestosResults([]);
    };
    var checkRepuestoAvailability = function (repuestoName, clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, repuestoExists, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!clientId || !repuestoName.trim()) {
                        setRepuestoAvailable(null); // No validar si no hay cliente o nombre
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getAllRepuestosEquipos(loggedIn)];
                case 2:
                    response = _a.sent();
                    repuestoExists = response.repuestoEquipos.some(function (repuesto) {
                        return repuesto.id_cliente._id === clientId &&
                            repuesto.repuesto_name.toLowerCase() === repuestoName.toLowerCase();
                    });
                    setRepuestoAvailable(!repuestoExists);
                    return [3 /*break*/, 5];
                case 3:
                    error_3 = _a.sent();
                    console.error('Error al verificar el repuesto:', error_3);
                    setRepuestoAvailable(null); // Resetear disponibilidad en caso de error
                    return [3 /*break*/, 5];
                case 4:
                    setRepuestoCheckLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name === 'repuesto_precio') {
            // Eliminar caracteres no numéricos
            var numericValue_1 = value.replace(/\D/g, ''); // Mantener solo dígitos
            // Actualizar el estado con el valor numérico
            setRepuestoEquipoData(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[name] = parseInt(numericValue_1) || 0, _a)));
            });
        }
        else {
            setRepuestoEquipoData(function (prevData) {
                var _a;
                return (__assign(__assign({}, prevData), (_a = {}, _a[name] = value, _a)));
            });
        }
        // Validación del nombre del repuesto
        if (name === 'repuesto_name' && value.trim()) {
            if (repuestoEquipoData.id_cliente) {
                setRepuestoCheckLoading(true);
                checkRepuestoAvailability(value, repuestoEquipoData.id_cliente);
            }
            else {
                setRepuestoAvailable(null); // No validar si no hay cliente
            }
        }
        else {
            setRepuestoAvailable(null); // Resetear disponibilidad para otros cambios
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var token, mappedData, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!repuestoEquipoData.id_cliente) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            { id: Date.now(), message: 'Seleccione un cliente antes de actualizar el repuesto', severity: 'warning' },
                        ], false); });
                        return [2 /*return*/];
                    }
                    if (!repuestoAvailable) {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            { id: Date.now(), message: 'El repuesto ya existe para el cliente seleccionado', severity: 'warning' },
                        ], false); });
                        return [2 /*return*/];
                    }
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Actualizando...', severity: 'info' }], false); });
                    setIsSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    token = loggedIn;
                    mappedData = {
                        id_cliente: repuestoEquipoData.id_cliente,
                        id_contrato: repuestoEquipoData.id_contrato,
                        repuesto_name: repuestoEquipoData.repuesto_name,
                        repuesto_precio: repuestoEquipoData.repuesto_precio,
                    };
                    return [4 /*yield*/, updateRepuestoEquipo(token, repuestoEquipoId, mappedData)];
                case 2:
                    _a.sent();
                    setTimeout(function () {
                        setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [
                            { id: Date.now(), message: 'Repuesto actualizado correctamente', severity: 'success' },
                        ], false); });
                        setTimeout(function () {
                            onEditSuccess();
                        }, 1000);
                    }, 1000);
                    return [3 /*break*/, 5];
                case 3:
                    error_4 = _a.sent();
                    console.error('Error al actualizar el repuesto equipo:', error_4);
                    setAlerts(function (prevAlerts) { return __spreadArray(__spreadArray([], prevAlerts, true), [{ id: Date.now(), message: 'Error al actualizar el repuesto', severity: 'error' }], false); });
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var calculatePercentage = function (precio, costo) {
        var percentage = ((precio - costo) / costo) * 100;
        return percentage.toFixed(2) + '%';
    };
    var handleAlertClose = function (id) {
        setAlerts(function (prevAlerts) { return prevAlerts.filter(function (alert) { return alert.id !== id; }); });
    };
    return (_jsxs("div", { children: [_jsx(MuiAlertComponent, { alerts: alerts, onClose: handleAlertClose }), _jsx("div", { className: "RegisterRepuestoEquipoForm-box", children: _jsx("form", { onSubmit: handleSubmit, className: "REGISTER-REPUESTO-EQUIPO-FORM", children: _jsx("div", { className: "box", children: _jsx("div", { className: "register-repuesto", children: _jsxs("div", { className: "overlap-group", children: [_jsx("div", { className: "overlap", children: _jsx("div", { className: "register-title", children: "ACTUALIZAR REPUESTO" }) }), _jsxs("div", { className: "cliente-div", children: [_jsx("label", { htmlFor: "id_cliente", className: "repuesto-cliente", children: "1. Cliente a relacionar:" }), _jsx("input", { type: "text", id: "id_cliente", className: "cliente-input", value: clientKeyword, onChange: function (e) {
                                                    setClientKeyword(e.target.value);
                                                    if (e.target.value.trim() === '') {
                                                        setClientResults([]); // Limpia resultados si el campo está vacío
                                                    }
                                                }, autoComplete: "off", placeholder: "Buscar cliente..." }), clientResults.length > 0 && clientKeyword !== ((_b = initialData.id_cliente) === null || _b === void 0 ? void 0 : _b.client_name) && (_jsx("ul", { className: "ul", children: clientResults.map(function (client) { return (_jsx("li", { onClick: function () { return handleSelectClient(client); }, className: "li", children: client.client_name }, client._id)); }) }))] }), _jsx("label", { htmlFor: "repuesto_name", className: "repuesto-nombre", children: "2. Ingrese el nombre del repuesto:" }), _jsx("input", { type: "text", id: "repuesto_name", name: "repuesto_name", value: repuestoEquipoData.repuesto_name, onChange: handleChange, className: "nombre-input", disabled: !repuestoEquipoData.id_cliente }), repuestoCheckLoading ? (_jsx(CircularProgress, { style: { color: '#00ddfa', position: 'absolute', top: '17.4vh', left: '35vw' }, size: 20 })) : (repuestoAvailable !== null && (_jsx("p", { className: repuestoAvailable ? 'repuesto-available' : 'repuesto-unavailable', children: repuestoAvailable ? 'Repuesto disponible' : 'Repuesto no disponible' }))), _jsxs("div", { className: 'repuesto-tecnico-div', children: [_jsx("label", { className: "repuesto-tecnico", children: "3. Seleccione el Repuesto T\u00E9cnico a relacionar:" }), _jsx("input", { className: "precio-tecnico-input", type: "text", id: "id_costo_repuesto", name: "id_costo_repuesto", value: costoRepuestosKeyword, onChange: function (e) { return setCostoRepuestosKeyword(e.target.value); }, autoComplete: "off", placeholder: 'Buscar Repuesto T\u00E9cnico...' }), costoRepuestosResults.length > 0 && (_jsx("ul", { className: "ul", children: costoRepuestosResults.map(function (costoRepuesto) { return (_jsx("li", { onClick: function () {
                                                        setRepuestoEquipoData(function (prevData) { return (__assign(__assign({}, prevData), { id_costo_repuesto: costoRepuesto._id })); });
                                                        setSelectedCostoRepuesto(costoRepuesto); // Guardamos el repuesto seleccionado
                                                        setCostoRepuestosKeyword("".concat(costoRepuesto.nombre_tecnico, " - ").concat(costoRepuesto.codigo, " - ").concat(formatCurrency(costoRepuesto.costo)));
                                                        setCostoRepuestosResults([]);
                                                    }, className: "li", children: "".concat(costoRepuesto.nombre_tecnico, " - ").concat(costoRepuesto.codigo, " - ").concat(formatCurrency(costoRepuesto.costo)) }, costoRepuesto._id)); }) }))] }), _jsx("label", { className: "repuesto-precio", children: "4. Ingrese el precio del repuesto sin IVA:" }), _jsx("input", { className: "precio-input", type: "text", id: "repuesto_precio", name: "repuesto_precio", value: repuestoEquipoData.repuesto_precio ? formatCurrency(repuestoEquipoData.repuesto_precio) : '', onChange: handleChange, disabled: !selectedCostoRepuesto, style: {
                                            backgroundColor: !selectedCostoRepuesto ? '#f2f2f2' : '',
                                            cursor: !selectedCostoRepuesto ? 'not-allowed' : 'text',
                                        } }), selectedCostoRepuesto && repuestoEquipoData.repuesto_precio > 0 && (_jsx("p", { style: {
                                            color: repuestoEquipoData.repuesto_precio >= selectedCostoRepuesto.costo
                                                ? 'green'
                                                : 'red',
                                            position: 'absolute',
                                            top: '22.48vh',
                                            left: '22.2vw',
                                            fontWeight: 'bold',
                                        }, children: repuestoEquipoData.repuesto_precio >= selectedCostoRepuesto.costo
                                            ? "Ganancia: ".concat(calculatePercentage(repuestoEquipoData.repuesto_precio, selectedCostoRepuesto.costo))
                                            : "El precio del repuesto NO puede ser menor al Costo. P\u00E9rdida: ".concat(calculatePercentage(repuestoEquipoData.repuesto_precio, selectedCostoRepuesto.costo)) })), _jsx("label", { htmlFor: "id_contrato", className: "repuesto-contrato", children: "4. Seleccione el contrato asociado:" }), _jsxs("select", { id: "id_contrato", name: "id_contrato", value: repuestoEquipoData.id_contrato, onChange: handleChange, className: "contrato-select-input", children: [_jsx("option", { value: "", disabled: true, children: "Seleccione un contrato" }), initialData.id_contrato && !contratos.find(function (contrato) { return contrato.id === initialData.id_contrato._id; }) && (_jsxs("option", { value: initialData.id_contrato._id, children: ["Contrato de ", ((_d = (_c = initialData.id_contrato) === null || _c === void 0 ? void 0 : _c.id_contrato_tipo) === null || _d === void 0 ? void 0 : _d.tipo) || 'N/A', " - ", ((_e = initialData.id_contrato) === null || _e === void 0 ? void 0 : _e.numero) || 'N/A', " - ", (_f = initialData.id_contrato) === null || _f === void 0 ? void 0 : _f._id] })), contratos.map(function (contrato) { return (_jsx("option", { value: contrato.id, children: contrato.displayText }, contrato.id)); })] }), _jsxs("div", { className: "RegisterRepuestoEquipoForm-actionButtons", children: [_jsx("button", { className: "registrar-text-wrapper", type: "submit", disabled: isSubmitting || repuestoCheckLoading, children: isSubmitting ? 'Actualizando...' : 'Actualizar' }), _jsx("button", { className: "cancelar-text-wrapper", onClick: onCancel, disabled: isSubmitting, children: "Cancelar" })] }), _jsx("div", { className: "repuesto-separator" })] }) }) }) }) })] }));
};
export default EditRepuestoEquipoButton;
