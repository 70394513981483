import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./styles/CaudalBombaPreventivoRegistrar.css";
var CaudalBombaPreventivoVer = function (_a) {
    var data = _a.data;
    var mediciones = data.mediciones, promedios = data.promedios;
    // Organizar las mediciones en tablas de 3 filas cada una
    var medicionesAgrupadas = mediciones.reduce(function (acc, curr, index) {
        var tableIndex = Math.floor(index / 3);
        if (!acc[tableIndex])
            acc[tableIndex] = [];
        acc[tableIndex].push(curr);
        return acc;
    }, []);
    return (_jsxs("div", { className: "CaudalBombaPreventivoRegistrar-container", children: [medicionesAgrupadas.map(function (table, tableIndex) { return (_jsxs("table", { className: "CaudalBombaPreventivoRegistrar-table", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Medici\u00F3n" }), _jsx("th", { children: "Caudal Calibrador (l/min)" }), _jsx("th", { children: "Caudal Patr\u00F3n (l/min)" }), _jsx("th", { children: "Error (%)" })] }) }), _jsxs("tbody", { children: [table.map(function (row, rowIndex) { return (_jsxs("tr", { children: [_jsx("td", { children: rowIndex + 1 }), _jsx("td", { children: row.calibrador }), _jsx("td", { children: row.patron }), _jsx("td", { children: row.error.toFixed(2) })] }, rowIndex)); }), _jsxs("tr", { children: [_jsx("td", { colSpan: 3, children: "Promedio" }), _jsx("td", { children: (table.reduce(function (sum, row) { return sum + row.error; }, 0) / table.length || 0).toFixed(2) })] })] })] }, tableIndex)); }), _jsxs("table", { className: "CaudalBombaPreventivoRegistrar-table", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Medici\u00F3n" }), _jsx("th", { children: "Error Promedio (%)" })] }) }), _jsx("tbody", { children: promedios.mediciones.map(function (promedio, index) { return (_jsxs("tr", { children: [_jsx("td", { children: promedio.medicion }), _jsx("td", { children: promedio.error.toFixed(2) })] }, index)); }) }), _jsx("tfoot", { children: _jsxs("tr", { children: [_jsx("td", { children: "Total Promedio" }), _jsx("td", { children: promedios.total.toFixed(2) })] }) })] })] }));
};
export default CaudalBombaPreventivoVer;
