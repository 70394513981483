import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClassIcon from '@mui/icons-material/Class';
import './styles/ClaseEquipoCard.css';
import Checkbox from '@mui/material/Checkbox';
var ClaseEquipoCard = function (_a) {
    var claseEquipo = _a.claseEquipo, onClick = _a.onClick, selectMode = _a.selectMode, isSelected = _a.isSelected, onSelectToggle = _a.onSelectToggle;
    var liClassName = "ClasesEquiposCard-box ".concat(isSelected ? 'ClasesEquiposCard-selected' : '', "\n    }");
    return (_jsxs("li", { className: liClassName, children: [selectMode && (_jsx(Checkbox, { checked: isSelected, onChange: function (e) {
                    e.stopPropagation(); // Evita que el click en el checkbox dispare otros eventos
                    onSelectToggle(); // Activa la función para agregar o quitar la orden de la selección
                }, className: "SolicitudServicioCard-select-checkbox", sx: {
                    position: 'absolute',
                    top: '10px',
                    left: '102%',
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    zIndex: 5, // Asegura que esté sobre los demás elementos
                    transform: 'scale(1.5)',
                    '&.Mui-checked': {
                        color: '#4F7A76',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(0, 221, 250, 0.1)',
                        borderRadius: '50%',
                    },
                    '& .MuiSvgIcon-root': {
                        fill: '#4F7A76',
                    },
                    '& input': {
                        zIndex: 3, // Ajuste del z-index del input en el DOM
                    },
                } })), _jsx("div", { className: "ClasesEquiposCard-overlap", children: _jsx(ClassIcon, { className: "ClasesEquiposCard-marcaequipo-icon" }) }), _jsx("div", { className: "ClasesEquiposCard-marcaequipo-title", onClick: onClick, children: claseEquipo.clase }), _jsxs("div", { className: "ClasesEquiposCard-marcaequipo-id", children: ["CLASE ID: ", claseEquipo._id] })] }, claseEquipo._id));
};
export default ClaseEquipoCard;
