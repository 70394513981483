var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';
import { useNavigate } from 'react-router-dom';
import './styles/EquiposPages.css';
import { CircularProgress } from '@mui/material';
import EquipoCard from '../components/equipos/EquipoCard';
import { getAllEquipos } from '../services/equiposService';
import EquiposFiltersHeader from '../components/equipos/filters/EquiposFiltersHeader';
import AdvancedFiltersEquipos from '../components/equipos/filters/AdvancedFiltersEquipos';
import EquiposActionsComponent from '../components/equipos/filters/EquiposActionsComponent';
import DashboardMenuLateral from '../../users/components/dashboard/DashboardMenulateral';
var EquiposPages = function () {
    var loggedIn = useSessionStorage('sessionJWTToken');
    var _a = useState([]), equipos = _a[0], setEquipos = _a[1];
    var _b = useState([]), filteredEquipos = _b[0], setFilteredEquipos = _b[1];
    var _c = useState([]), selectedEquipos = _c[0], setSelectedEquipos = _c[1];
    var _d = useState(false), equiposSelected = _d[0], setEquiposSelected = _d[1];
    var _e = useState(false), selectMode = _e[0], setSelectMode = _e[1];
    var _f = useState(false), showSearchResults = _f[0], setShowSearchResults = _f[1];
    var _g = useState([]), searchResults = _g[0], setSearchResults = _g[1];
    var _h = useState(true), loading = _h[0], setLoading = _h[1];
    var _j = useState(1), currentPage = _j[0], setCurrentPage = _j[1];
    var _k = useState(0), totalPages = _k[0], setTotalPages = _k[1];
    var _l = useState(1000), limitPerPage = _l[0], setLimitPerPage = _l[1];
    var _m = useState(0), totalItems = _m[0], setTotalItems = _m[1];
    var _o = useState(''), searchKeyword = _o[0], setSearchKeyword = _o[1];
    var _p = useState(false), showAdvancedFiltersModal = _p[0], setShowAdvancedFiltersModal = _p[1];
    var _q = useState(false), showDownloadEquiposModal = _q[0], setShowDownloadEquiposModal = _q[1];
    var _r = useState(false), filtered = _r[0], setFiltered = _r[1];
    var _s = useState(null), lastSearchType = _s[0], setLastSearchType = _s[1];
    var navigate = useNavigate();
    var fetchEquipos = function (page, limit) { return __awaiter(void 0, void 0, void 0, function () {
        var token, result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    token = loggedIn;
                    return [4 /*yield*/, getAllEquipos(token, limit, page)];
                case 1:
                    result = _a.sent();
                    setEquipos(result.equipos);
                    setTotalPages(result.totalPages || Math.ceil(result.totalItems / limit));
                    setTotalItems(result.totalItems);
                    setLoading(false);
                    setSelectedEquipos([]); // Resetear selección cuando se cargan todos los equipos
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error al obtener equipos:', error_1);
                    window.location.href = '/login';
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleSearch = function (keyword, page, limit) { return __awaiter(void 0, void 0, void 0, function () {
        var token, result, keywordLower_1, searchInObject_1, filteredEquipos_1, paginatedResults, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    setSearchKeyword(keyword);
                    setShowSearchResults(true);
                    token = loggedIn;
                    return [4 /*yield*/, getAllEquipos(token)];
                case 1:
                    result = _a.sent();
                    keywordLower_1 = keyword.toLowerCase();
                    searchInObject_1 = function (obj) {
                        return Object.values(obj).some(function (value) {
                            if (typeof value === 'object' && value !== null) {
                                return searchInObject_1(value);
                            }
                            return String(value).toLowerCase().includes(keywordLower_1);
                        });
                    };
                    filteredEquipos_1 = result.equipos.filter(function (equipo) { return searchInObject_1(equipo); });
                    paginatedResults = filteredEquipos_1.slice((page - 1) * limit, page * limit);
                    setSearchResults(paginatedResults);
                    setTotalPages(Math.ceil(filteredEquipos_1.length / limit));
                    setTotalItems(filteredEquipos_1.length);
                    setLastSearchType('keyword');
                    setSelectedEquipos([]);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error al buscar equipos:', error_2);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleAdvancedSearch = function (filteredData, totalItems) {
        setFilteredEquipos(filteredData);
        setTotalItems(totalItems);
        setTotalPages(Math.ceil(totalItems / limitPerPage));
        setCurrentPage(1);
        setShowSearchResults(false);
        setFiltered(true);
        setLastSearchType('advancedFilters');
        setSelectedEquipos([]);
        var paginatedResults = filteredData.slice(0, limitPerPage);
        setSearchResults(paginatedResults);
    };
    useEffect(function () {
        if (showAdvancedFiltersModal)
            return;
        if (showSearchResults) {
            return;
        }
        if (filtered) {
            var paginatedResults = filteredEquipos.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage);
            setSearchResults(paginatedResults);
            setTotalPages(Math.ceil(filteredEquipos.length / limitPerPage));
            return;
        }
        fetchEquipos(currentPage, limitPerPage);
    }, [currentPage, limitPerPage, showSearchResults, showAdvancedFiltersModal, filtered]);
    useEffect(function () {
        setEquiposSelected(selectedEquipos.length > 0);
    }, [selectedEquipos]);
    useEffect(function () {
        if (lastSearchType === 'advancedFilters' && showSearchResults) {
            setShowSearchResults(false);
            setFiltered(true);
        }
        if (lastSearchType === 'keyword' && filtered) {
            setFiltered(false);
            setShowSearchResults(true);
        }
    }, [lastSearchType, showSearchResults, filtered]);
    var handleSelectToggle = function (id) {
        setSelectedEquipos(function (prevSelectedEquipos) {
            if (prevSelectedEquipos.includes(id)) {
                return prevSelectedEquipos.filter(function (equipoId) { return equipoId !== id; });
            }
            else {
                return __spreadArray(__spreadArray([], prevSelectedEquipos, true), [id], false);
            }
        });
    };
    var handleSelectAllToggle = function () {
        var displayedEquipos = showSearchResults ? searchResults : filtered ? filteredEquipos : equipos;
        if (selectedEquipos.length === displayedEquipos.length) {
            setSelectedEquipos([]);
        }
        else {
            setSelectedEquipos(displayedEquipos.map(function (equipo) { return equipo._id; }));
        }
    };
    var handleSelectModeToggle = function () {
        setSelectMode(!selectMode);
        setSelectedEquipos([]);
    };
    var handleAdvancedFiltersClose = function () {
        setShowAdvancedFiltersModal(false);
        if (lastSearchType === 'keyword') {
            setShowSearchResults(true);
            setFiltered(false);
        }
        else if (lastSearchType === 'advancedFilters') {
            setShowSearchResults(false);
            setFiltered(true);
        }
    };
    var navigateToEquipoDetail = function (id) {
        if (!selectMode) {
            navigate("/equipos/".concat(id));
        }
        else {
            handleSelectToggle(id);
        }
    };
    var handlePageChange = function (newPage) {
        var pageNumber = typeof newPage === 'function' ? newPage(currentPage) : newPage;
        setCurrentPage(pageNumber);
        if (showSearchResults) {
            handleSearch(searchKeyword, pageNumber, limitPerPage);
        }
        else if (filtered) {
            var paginatedResults = filteredEquipos.slice((pageNumber - 1) * limitPerPage, pageNumber * limitPerPage);
            setSearchResults(paginatedResults);
        }
        else {
            fetchEquipos(pageNumber, limitPerPage);
        }
    };
    var handleLimitChange = function (newLimit) {
        var limitNumber = typeof newLimit === 'function' ? newLimit(limitPerPage) : newLimit;
        setLimitPerPage(limitNumber);
        setCurrentPage(1);
        if (showSearchResults) {
            handleSearch(searchKeyword, 1, limitNumber);
        }
        else if (filtered) {
            var paginatedResults = filteredEquipos.slice(0, limitNumber);
            setSearchResults(paginatedResults);
        }
        else {
            fetchEquipos(1, limitNumber);
        }
    };
    return (_jsxs("div", { className: 'EquiposPages-container', children: [_jsx(DashboardMenuLateral, {}), _jsx(EquiposFiltersHeader, { currentPage: currentPage, setCurrentPage: handlePageChange, totalPages: totalPages, selectMode: selectMode, handleSelectModeToggle: handleSelectModeToggle, handleSelectAllToggle: handleSelectAllToggle, selectedEquipos: selectedEquipos, equipos: showSearchResults ? searchResults : filtered ? filteredEquipos.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage) : equipos, limitPerPage: limitPerPage, setLimitPerPage: handleLimitChange, showSearchResults: showSearchResults, searchResults: searchResults, totalItems: showSearchResults
                    ? totalItems
                    : filtered
                        ? filteredEquipos.length
                        : totalItems, setShowSearchResults: setShowSearchResults, setSearchResults: setSearchResults, handleSearch: handleSearch, setSearchKeyword: setSearchKeyword, setShowAdvancedFiltersEquiposModal: setShowAdvancedFiltersModal, setShowDownloadEquiposModal: setShowDownloadEquiposModal, EquiposPageType: "Equipos-Pages" }), _jsx("div", { className: 'EquiposPages-Container-Card', children: loading ? (_jsx("div", { className: "EquiposPages-loading", children: _jsx(CircularProgress, { style: { color: '#4F7A76', position: 'absolute', top: '40%', left: '50%' } }) })) : (_jsx("ul", { className: 'EquiposPages-ul-cards', children: (showSearchResults ? searchResults : filtered ? filteredEquipos.slice((currentPage - 1) * limitPerPage, currentPage * limitPerPage) : equipos).map(function (equipo) { return (_jsx(EquipoCard, { equipo: equipo, onClick: function () { return navigateToEquipoDetail(equipo._id); }, selectMode: selectMode, isSelected: selectedEquipos.includes(equipo._id), onSelectToggle: function () { return handleSelectToggle(equipo._id); } }, equipo._id)); }) })) }), showAdvancedFiltersModal && (_jsx(AdvancedFiltersEquipos, { isOpen: showAdvancedFiltersModal, onClose: handleAdvancedFiltersClose, onApplyFilters: handleAdvancedSearch, currentPage: currentPage, limitPerPage: limitPerPage })), showDownloadEquiposModal && (_jsx(EquiposActionsComponent, { isOpen: showDownloadEquiposModal, onClose: function () { return setShowDownloadEquiposModal(false); }, equipos: selectedEquipos.length > 0
                    ? selectedEquipos.map(function (id) { return (showSearchResults ? searchResults : filtered ? filteredEquipos : equipos).find(function (equipo) { return equipo._id === id; }); })
                    : (showSearchResults ? searchResults : filtered ? filteredEquipos : equipos), selectMode: selectMode, equiposSelected: equiposSelected }))] }));
};
export default EquiposPages;
